import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag'

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';

import Icon from '@material-ui/core/Icon';
import MuiForm from 'rjsf-material-ui';




const ConfigControl = props => {
  const projectID = props.projectID
  const dataControls = useQuery(getControls, { variables:   {projectID} })
  const [ updateFeedbackControlMutation ] = useMutation(updateFeedbackControl)
  const [ updateModelParameterMutation ] = useMutation(updateModelParameter)
  const [ disconnectConnectControlModelMutation ] = useMutation(disconnectConnectControlModel)
  const [ createControlMutation ] = useMutation(createControl)

  if (dataControls.loading) return (<Grid><CircularProgress size={50} color="secondary" /></Grid>)
  if (dataControls.error) return (<Grid><Typography gutterBottom>Error fetching Controls data!</Typography></Grid>)
  if (!dataControls.data.controls) return (<Typography gutterBottom>No controls Found!</Typography>)
  var greyboxControlModels = [...new Set(dataControls.data.controls.map((control) => control.controlModels.find((controlModel) => controlModel.controlModelType === "greybox")).flat())].filter((e) => e !== undefined)
  var scheduleControlModels = [...new Set(dataControls.data.controls.map((control) => control.controlModels.find((controlModel) => controlModel.controlModelType === "schedule")).flat())].filter((e) => e !== undefined)
  var feedbackControls = [...new Set(dataControls.data.controls.map((control) => control.feedbackControls).flat())].filter((e) => e !== undefined)
  var activeControls = [...new Set(dataControls.data.controls.map((control) => control.activeOnBuilding?.hasOwnProperty("id")? control:undefined).flat())].filter((e) => e !== undefined)
  var nonActiveControls = [...new Set(dataControls.data.controls.map((control) => control.activeOnBuilding?.hasOwnProperty("id")? undefined:control).flat())].filter((e) => e !== undefined)
  var activeControl = (activeControls.length===1)?activeControls[0]:{}

  const handleFeedbackControlFormSubmit = ({formData}, fbcId, activeControl, nonActiveControls) => {
    //activate deactivate FBC
    // if activate/deactivate and nothing happens
    if (nonActiveControls.length === 0 && activeControl) {
      createControlMutation({
        variables: {
          projectID: projectID,
        },
        refetchQueries: [{
          query: getControls,
          variables: { projectID: projectID }
        }],
      }).then(response => {

      });
    }
    if (nonActiveControls.length > 0) {
      var fbcControlId = (formData.active && activeControl) ? activeControl.id : nonActiveControls[0].id
      updateFeedbackControlMutation({
        variables: {
          id: fbcId,
          controlId: fbcControlId,
          parameters: JSON.stringify(formData.parameters),
        },
        refetchQueries: [{
          query: getControls,
          variables: { projectID: projectID }
        }],
      }).then(response => {
        // handle the response
      });
    }
  
  }

  const handleControlModelFormSubmit = ({formData}, modelParameter, Id, activeControl) => {
    //activate deactivate FBC
    // if activate/deactivate and nothing happens

    // what if active control does not exist
    if (activeControl) {
      disconnectConnectControlModelMutation({
        variables: {
          id: Id,
          controlId: activeControl.id,
          active: formData.active
        },
      }).then(response => {
        // handle the response
      });
    }
    if (modelParameter===undefined)
    {
      return null
    }
    var payload = modelParameter.payload
    payload.data = formData.data
    updateModelParameterMutation({
      variables: {
        id: modelParameter.id,
        payload: JSON.stringify(payload),
      },
      refetchQueries: [{ query: getControls ,
      variables:{projectID:projectID}}],
    }).then(response => {
    // handle the response
    });

  }
  return (
    <Accordion key={"general"}  TransitionProps={{ unmountOnExit: true }} defaultExpanded={true}>
      <Accordion key={"fbc"}  TransitionProps={{ unmountOnExit: true }} defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" gutterBottom>{"Feedback Control"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid style={{width: "100%"}}>
            {feedbackControls.map(fbc => {
                var initialActive = fbc.control?.activeOnBuilding?.id?true:false
              return (<Accordion key={fbc.displayName} defaultExpanded={false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {fbc.control?.activeOnBuilding?.id? 
                  <Icon color="secondary" >check_circle</Icon> :
                  <Icon color="disabled" >highlight_off</Icon>}
                  <Typography key="bn" variant="h6" gutterBottom>{fbc.displayName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <MuiForm schema={fbcSchema} formData={{...fbc,...{'active':initialActive}}} onSubmit={(formData) => handleFeedbackControlFormSubmit(formData, fbc.id, activeControl, nonActiveControls)}>
                    <Button style={{ display: 'inline-block' }}  variant="contained" color="primary" type="submit"  >
                  Update 
                </Button>
                    </MuiForm>
                </AccordionDetails>
              </Accordion>
            )}
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion key={"greybox"}  TransitionProps={{ unmountOnExit: true }} defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" gutterBottom>{"Greybox Control"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid style={{width: "100%"}}>
          {greyboxControlModels.map(greybox => {
              var activeControlModel = greybox.controls.find((control) => control.activeOnBuilding?.id? true:false)?true:false
              var modelParameter = greybox.modelParameters.find((modelParameter)=> modelParameter.displayName==='pars_ocp')
              var pars = {...(modelParameter?.payload)?modelParameter.payload:{'data':{}},
              ...{'active':activeControlModel}}
              return (<Accordion key={greybox.slug} defaultExpanded={false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {activeControlModel?
                  <Icon color="secondary" >check_circle</Icon> :
                  <Icon color="disabled" >highlight_off</Icon>}
                  <Typography key="bn" variant="h6" gutterBottom>{greybox.slug}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <MuiForm schema={greyboxSchema} formData={pars} onSubmit={(formData) => handleControlModelFormSubmit(formData, modelParameter, greybox.id, activeControl)} >
                    <Button style={{ display: 'inline-block' }}  variant="contained" color="primary" type="submit" >
                  Update 
                </Button>
                    </MuiForm>
                </AccordionDetails>
              </Accordion>
            )}
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion key={"schedule"}  TransitionProps={{ unmountOnExit: true }} defaultExpanded={false}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" gutterBottom>{"Schedule Control"}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid style={{width: "100%"}}>
          {scheduleControlModels.map(schedule => {
              var activeControlModel = schedule.controls.find((control) => control.activeOnBuilding?.id? true:false)?true:false
              var modelParameter = schedule.modelParameters.find((modelParameter)=> modelParameter.displayName==='system_metric_priority')
              var pars = {...(modelParameter?.payload)?modelParameter.payload:{'data':[]},
              ...{'active':activeControlModel}}
              return (<Accordion key={schedule.slug} defaultExpanded={false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {activeControlModel?
                  <Icon color="secondary" >check_circle</Icon> :
                  <Icon color="disabled" >highlight_off</Icon>}
                  <Typography key="bn" variant="h6" gutterBottom>{schedule.slug}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <MuiForm schema={scheduleSchema} formData={pars} onSubmit={(formData) => handleControlModelFormSubmit(formData, modelParameter, schedule.id, activeControl)} >
                    <Button style={{ display: 'inline-block' }}  variant="contained" color="primary" type="submit" >
                  Update 
                </Button>
                    </MuiForm>
                </AccordionDetails>
              </Accordion>
            )}
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Accordion>
  );
}

const createControl = gql`
mutation createControl($projectID: String!) {
  createControl(
    projectID: $projectID
  ) { id }
}`;

const updateFeedbackControl = gql`
mutation updateFeedbackControl($id: ID!, $controlId: ID!, $parameters: Json!) {
  updateFeedbackControl(
    id: $id
    controlId: $controlId
    parameters: $parameters
  ) { id }
}`;

const updateModelParameter = gql`
mutation updateModelParameter($id: ID!,  $payload: Json!) {
  updateModelParameter(
    id: $id
    payload: $payload
  ) { id }
}`;

const disconnectConnectControlModel = gql`
mutation disconnectConnectControlModel($id:ID!, $controlId:ID!, $active: Boolean!) {
  disconnectConnectControlModel (id:$id, controlId:$controlId, active:$active)
  {  id }
}`;



const getControls = gql`
query getControls($projectID: String!) {
  controls (projectID:$projectID){
    id
    activeOnBuilding{id}
    controlModels { id controlModelType slug modelParameters{id displayName type payload} controls{activeOnBuilding{id}}}
    feedbackControls{
      id
      control{activeOnBuilding{id}}
      rawTrajectoriesOutput{tagname}
      rawTrajectoriesInput{tagname}
      systemMetricsInput{systemMetricTemplate{name} id}
      modelMetricsInput{modelMetricTemplate{name} id}
      liberation{systemMetricTemplate{name} id}
      displayName
      parameters
      condition
      steerValues
      clearValues
    }
    
  }
}`;

const fbcSchema = {
  "type": "object",
  "properties": {
    "parameters": {
      "type": "object",
          "additionalProperties": {
              "type": "number",
          }
  },
  "condition": {
    "type": "string"
  },
  "active": {
    "type": "boolean"
  }

  }
}
const greyboxSchema = {
  "type": "object",
  "properties": {
    "data": {
      "type": "object",
          "additionalProperties": {
              "type": "number",
          }
        
  },
  "condition": {
    "type": "string"
  },
  "active": {
    "type": "boolean"
  }

  }
}

const scheduleSchema = {
  "type": "object",
  "properties": {
    "data": {
      "type": "array",
      "items": {
        "type": "string",
      }

    },
    "active": {
      "type": "boolean"
    }
  }
}


export default ConfigControl;
