import React, {useState} from 'react';
import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';

import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";

import {CurrentUserContext} from '../contexts/CurrentUser';

import {Crosshair, CustomSVGSeries, HorizontalGridLines, VerticalBarSeries, XAxis, XYPlot, YAxis,} from 'react-vis';
import 'react-vis/dist/style.css'
import deCompressReporting from "./ResultsSavingsDecompression";

const BuildingSavingsQuery = gql`
query Building ($projectID: String) {
  building (where: {projectID: $projectID}) {
    id
    projectID
    buildingFeatures (where: {feature:{name: "evaluation-ipmvp"}}) {
        id
        reporting
        config
    }
  }
}`
const colorHeatBaseline = "#a6d1e3"
const colorHeatConsumption = "#007db0"
const colorHeatSavings = "#cee7eb"
const colorColdBaseline = "#509680"
const colorColdConsumption = "#02eba2"
const colorColdSavings = "#cbf5e8"
const colorOutlier = "#e3e3e3"
const ResultsTabPreviewSavings = props => {
    const [crosshairValues, setCrosshairValues] = useState([]);
    const [chKey, setChKey] = useState(null);

    const {loading, error, data} = useQuery(BuildingSavingsQuery, {
        variables: {projectID: props.match.params.projectID}, onCompleted: () => {
            setCrosshairValues([])
        }
    })

    const _yTickFormatValue = (unit) => ((v, i, scale, tickTotal) => {
        return `${(scale.tickFormat(tickTotal, 'd')(v)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1\u00a0')} ` + unit;
    });

    if (loading) return (<Paper style={{padding: 25}}><CircularProgress size={50} color="secondary"/></Paper>)
    if (error) return (
        <Paper style={{padding: 25}}><Typography>An error occurred - results data not available</Typography></Paper>)
    if (!(data.building && data.building.buildingFeatures && data.building.buildingFeatures.length === 1 && data.building.buildingFeatures[0].reporting && deCompressReporting(data.building.buildingFeatures[0].reporting).private)) return (
        <Paper style={{padding: 25}}><Typography>No reporting data found</Typography></Paper>)

    const reporting_data = deCompressReporting(data.building.buildingFeatures[0].reporting).private
    const notes_from_config = data.building.buildingFeatures[0].config.Notes
    const gas_notes_from_config = notes_from_config && notes_from_config.gas ? notes_from_config.gas : []

    const gas_vars = reporting_data.gas
    const gas_label_keys = gas_vars.baseline_chart_data ? (Object.keys(gas_vars.baseline_chart_data).sort().concat(Object.keys(gas_vars.reporting_chart_data).sort()).concat(Object.keys(gas_vars.neutralise_period_chart_data).sort()).concat(Object.keys(gas_vars.excluded_chart_data).sort())).sort() : []
    const gas_label_strings = gas_label_keys.map((k) => k.replace('|', ' - '))
    const gas_label_indexes = gas_label_keys.map((k, i) => i)
    const gas_baseline_data = gas_label_keys.map((k, i) => ({
        x: i, y: k in gas_vars.baseline_chart_data ? gas_vars.baseline_chart_data[k].corrected_float : 0
    }))
    const gas_reporting_data = gas_label_keys.map((k, i) => ({
        x: i, y: k in gas_vars.reporting_chart_data ? gas_vars.reporting_chart_data[k].corrected_float : 0
    }))
    const gas_outlier_data = gas_label_keys.map((k, i) => ({
        x: i,
        y: (k in gas_vars.neutralise_period_chart_data) ? (gas_vars.neutralise_period_chart_data[k].corrected_float) : ((k in gas_vars.excluded_chart_data) ? gas_vars.excluded_chart_data[k].corrected_float : 0)
    }))
    // const gas_predicted_data = gas_label_keys.map((k, i) => ({
    //     x: i,
    //     y: k in gas_vars.reporting_chart_data ? gas_vars.reporting_chart_data[k].predicted_corrected_float : 0
    // }))
    const gas_savings_data = gas_label_keys.map((k, i) => ({
        x: i,
        y: k in gas_vars.reporting_chart_data && gas_vars.reporting_chart_data[k].absolute_savings_float >= 0 ? gas_vars.reporting_chart_data[k].absolute_savings_float : 0
    }))
    const gas_losses_data = gas_label_keys.map((k, i) => ({
        x: i,
        y: k in gas_vars.reporting_chart_data && gas_vars.reporting_chart_data[k].absolute_savings_float < 0 ? gas_vars.reporting_chart_data[k].absolute_savings_float : 0
    }))
    // const gas_savings_ans_losses_data = gas_label_keys.map((k, i) => ({
    //     x: i,
    //     y: k in gas_vars.reporting_chart_data ? gas_vars.reporting_chart_data[k].absolute_savings_float : 0
    // }))

    const max_gas_value = gas_vars.baseline_chart_data ? Math.max(Math.max(...Object.values(gas_vars.baseline_chart_data).map(v => v.corrected_float)), Math.max(...Object.values(gas_vars.reporting_chart_data).map(v => v.corrected_float + v.absolute_savings_float))) : 0
    const gas_start_mark = gas_vars && gas_vars.activation_date ? [{
        x: gas_label_keys.findIndex(k => k.substring(0, 7) === gas_vars.activation_date.substring(0, 7)) - 0.5 + 0.032 * parseInt(gas_vars.activation_date.substring(8, 10), 10),
        y: max_gas_value * 1.1,
        customComponent: (row, positionInPixels) => {
            return (<g className="inner-inner-component">
                <line x1="0" y1="3" x2="0" y2="200" stroke="#666666" strokeWidth="1"/>
                <rect x={positionInPixels.x > 600 ? -120 : 0} width="160" height="20" rx="4" fill="#EEEEEE"
                      stroke="#666666"/>
                <text x={positionInPixels.x > 600 ? -120 : 0} y={0} style={{fontSize: "12px"}}>
                    <tspan x={positionInPixels.x > 900 ? -110 : 10} y="14">{`Activation Date Savings`}</tspan>
                </text>
            </g>);
        }
    }] : []

    const electricity_vars = reporting_data.electricity
    const electricity_notes_from_config = notes_from_config && notes_from_config.electricity ? notes_from_config.electricity : []
    const electricity_label_keys = electricity_vars.baseline_chart_data ? (Object.keys(electricity_vars.baseline_chart_data).sort().concat(Object.keys(electricity_vars.reporting_chart_data).sort()).concat(Object.keys(electricity_vars.neutralise_period_chart_data).sort()).concat(Object.keys(electricity_vars.excluded_chart_data).sort())).sort() : []
    const electricity_label_strings = electricity_label_keys.map((k) => k.replace('|', ' - '))
    const electricity_label_indexes = electricity_label_keys.map((k, i) => i)
    const electricity_baseline_data = electricity_label_keys.map((k, i) => ({
        x: i, y: k in electricity_vars.baseline_chart_data ? electricity_vars.baseline_chart_data[k].corrected_float : 0
    }))
    const electricity_reporting_data = electricity_label_keys.map((k, i) => ({
        x: i,
        y: k in electricity_vars.reporting_chart_data ? electricity_vars.reporting_chart_data[k].corrected_float : 0
    }))
    // const electricity_predicted_data = electricity_label_keys.map((k, i) => ({
    //     x: i,
    //     y: k in electricity_vars.reporting_chart_data ? electricity_vars.reporting_chart_data[k].predicted_corrected_float : 0
    // }))
    const electricity_outlier_data = electricity_label_keys.map((k, i) => ({
        x: i,
        y: k in electricity_vars.neutralise_period_chart_data ? electricity_vars.neutralise_period_chart_data[k].corrected_float : k in electricity_vars.excluded_chart_data ? electricity_vars.excluded_chart_data[k].corrected_float : 0
    }))
    const electricity_savings_data = electricity_label_keys.map((k, i) => ({
        x: i,
        y: k in electricity_vars.reporting_chart_data && electricity_vars.reporting_chart_data[k].absolute_savings_float >= 0 ? electricity_vars.reporting_chart_data[k].absolute_savings_float : 0
    }))
    const electricity_losses_data = electricity_label_keys.map((k, i) => ({
        x: i,
        y: k in electricity_vars.reporting_chart_data && electricity_vars.reporting_chart_data[k].absolute_savings_float < 0 ? electricity_vars.reporting_chart_data[k].absolute_savings_float : 0
    }))
    // const electricity_savings_and_losses_data = electricity_label_keys.map((k, i) => ({
    //     x: i,
    //     y: k in electricity_vars.reporting_chart_data ? electricity_vars.reporting_chart_data[k].absolute_savings_float : 0
    // }))

    const max_electricity_value = electricity_vars.baseline_chart_data ? Math.max(Math.max(...Object.values(electricity_vars.baseline_chart_data).map(v => v.corrected_float)), Math.max(...Object.values(electricity_vars.reporting_chart_data).map(v => v.corrected_float + v.absolute_savings_float))) : 0
    const electricity_start_mark = electricity_vars && electricity_vars.activation_date ? [{
        x: electricity_label_keys.findIndex(k => k.substring(0, 7) === electricity_vars.activation_date.substring(0, 7)) - 0.5 + 0.032 * parseInt(electricity_vars.activation_date.substring(8, 10), 10),
        y: max_electricity_value * 1.1,
        customComponent: (row, positionInPixels) => {
            return (<g className="inner-inner-component">
                <line x1="0" y1="3" x2="0" y2="200" stroke="#666666" strokeWidth="1"/>
                <rect x={positionInPixels.x > 600 ? -120 : 0} width="160" height="20" rx="4" fill="#EEEEEE"
                      stroke="#666666"/>
                <text x={positionInPixels.x > 600 ? -120 : 0} y={0} style={{fontSize: "12px"}}>
                    <tspan x={positionInPixels.x > 600 ? -110 : 10} y="14">{`Activation Date Savings`}</tspan>
                </text>
            </g>);
        }
    }] : []


    const cold_vars = reporting_data.cold
    const cold_notes_from_config = notes_from_config && notes_from_config.cold ? notes_from_config.cold : []
    const cold_label_keys = cold_vars && cold_vars.baseline_chart_data ? (Object.keys(cold_vars.baseline_chart_data).sort().concat(Object.keys(cold_vars.reporting_chart_data).sort()).concat(Object.keys(cold_vars.neutralise_period_chart_data).sort()).concat(Object.keys(cold_vars.excluded_chart_data).sort())).sort() : []
    const cold_label_strings = cold_label_keys.map((k) => k.replace('|', ' - '))
    const cold_label_indexes = cold_label_keys.map((k, i) => i)
    const cold_baseline_data = cold_label_keys.map((k, i) => ({
        x: i, y: k in cold_vars.baseline_chart_data ? cold_vars.baseline_chart_data[k].corrected_float : 0
    }))
    const cold_reporting_data = cold_label_keys.map((k, i) => ({
        x: i, y: k in cold_vars.reporting_chart_data ? cold_vars.reporting_chart_data[k].corrected_float : 0
    }))
    const cold_outlier_data = cold_label_keys.map((k, i) => ({
        x: i,
        y: k in cold_vars.neutralise_period_chart_data ? cold_vars.neutralise_period_chart_data[k].corrected_float : k in cold_vars.excluded_chart_data ? cold_vars.excluded_chart_data[k].corrected_float : 0
    }))
    // const cold_predicted_data = cold_label_keys.map((k, i) => ({
    //     x: i,
    //     y: k in cold_vars.reporting_chart_data ? cold_vars.reporting_chart_data[k].predicted_corrected_float : 0
    // }))
    const cold_savings_data = cold_label_keys.map((k, i) => ({
        x: i,
        y: k in cold_vars.reporting_chart_data && cold_vars.reporting_chart_data[k].absolute_savings_float >= 0 ? cold_vars.reporting_chart_data[k].absolute_savings_float : 0
    }))
    const cold_losses_data = cold_label_keys.map((k, i) => ({
        x: i,
        y: k in cold_vars.reporting_chart_data && cold_vars.reporting_chart_data[k].absolute_savings_float < 0 ? cold_vars.reporting_chart_data[k].absolute_savings_float : 0
    }))
    // const cold_savings_and_losses_data = cold_label_keys.map((k, i) => ({
    //     x: i,
    //     y: k in cold_vars.reporting_chart_data ? cold_vars.reporting_chart_data[k].absolute_savings_float : 0
    // }))

    const max_cold_value = cold_vars && cold_vars.activation_date && cold_vars.baseline_chart_data ? Math.max(Math.max(...Object.values(cold_vars.baseline_chart_data).map(v => v.corrected_float)), Math.max(...Object.values(cold_vars.reporting_chart_data).map(v => v.corrected_float + v.absolute_savings_float))) : 0
    const cold_start_mark = cold_vars && cold_vars.activation_date ? [{
        x: cold_label_keys.findIndex(k => k.substring(0, 7) === cold_vars.activation_date.substring(0, 7)) - 0.5 + 0.032 * parseInt(cold_vars.activation_date.substring(8, 10), 10),
        y: max_cold_value * 1.1,
        customComponent: (row, positionInPixels) => {
            return (<g className="inner-inner-component">
                <line x1="0" y1="3" x2="0" y2="200" stroke="#666666" strokeWidth="1"/>
                <rect x={positionInPixels.x > 600 ? -120 : 0} width="160" height="20" rx="4" fill="#EEEEEE"
                      stroke="#666666"/>
                <text x={positionInPixels.x > 600 ? -120 : 0} y={0} style={{fontSize: "12px"}}>
                    <tspan x={positionInPixels.x > 600 ? -110 : 10} y="14">{`Activation Date Savings`}</tspan>
                </text>
            </g>);
        }
    }] : []

    const heat_vars = reporting_data.heat
    const heat_notes_from_config = notes_from_config && notes_from_config.heat ? notes_from_config.heat : []
    const heat_label_keys = heat_vars && heat_vars.baseline_chart_data ? (Object.keys(heat_vars.baseline_chart_data).sort().concat(Object.keys(heat_vars.reporting_chart_data).sort()).concat(Object.keys(heat_vars.neutralise_period_chart_data).sort()).concat(Object.keys(heat_vars.excluded_chart_data).sort())).sort() : []
    const heat_label_strings = heat_label_keys.map((k) => k.replace('|', ' - '))
    const heat_label_indexes = heat_label_keys.map((k, i) => i)
    const heat_baseline_data = heat_label_keys.map((k, i) => ({
        x: i, y: k in heat_vars.baseline_chart_data ? heat_vars.baseline_chart_data[k].corrected_float : 0
    }))
    const heat_reporting_data = heat_label_keys.map((k, i) => ({
        x: i, y: k in heat_vars.reporting_chart_data ? heat_vars.reporting_chart_data[k].corrected_float : 0
    }))
    const heat_outlier_data = heat_label_keys.map((k, i) => ({
        x: i,
        y: k in heat_vars.neutralise_period_chart_data ? heat_vars.neutralise_period_chart_data[k].corrected_float : k in heat_vars.excluded_chart_data ? heat_vars.excluded_chart_data[k].corrected_float : 0
    }))
    // const heat_predicted_data = heat_label_keys.map((k, i) => ({
    //     x: i,
    //     y: k in heat_vars.reporting_chart_data ? heat_vars.reporting_chart_data[k].predicted_corrected_float : 0
    // }))
    const heat_savings_data = heat_label_keys.map((k, i) => ({
        x: i,
        y: k in heat_vars.reporting_chart_data && heat_vars.reporting_chart_data[k].absolute_savings_float >= 0 ? heat_vars.reporting_chart_data[k].absolute_savings_float : 0
    }))
    const heat_losses_data = heat_label_keys.map((k, i) => ({
        x: i,
        y: k in heat_vars.reporting_chart_data && heat_vars.reporting_chart_data[k].absolute_savings_float < 0 ? heat_vars.reporting_chart_data[k].absolute_savings_float : 0
    }))
    // const heat_savings_and_losses_data = heat_label_keys.map((k, i) => ({
    //     x: i,
    //     y: k in heat_vars.reporting_chart_data ? heat_vars.reporting_chart_data[k].absolute_savings_float : 0
    // }))

    const max_heat_value = heat_vars && heat_vars.baseline_chart_data ? Math.max(Math.max(...Object.values(heat_vars.baseline_chart_data).map(v => v.corrected_float)), Math.max(...Object.values(heat_vars.reporting_chart_data).map(v => v.corrected_float + v.absolute_savings_float))) : 0
    const heat_start_mark = heat_vars && heat_vars.activation_date ? [{
        x: heat_label_keys.findIndex(k => k.substring(0, 7) === heat_vars.activation_date.substring(0, 7)) - 0.5 + 0.032 * parseInt(heat_vars.activation_date.substring(8, 10), 10),
        y: max_heat_value * 1.1,
        customComponent: (row, positionInPixels) => {
            return (<g className="inner-inner-component">
                <line x1="0" y1="3" x2="0" y2="200" stroke="#666666" strokeWidth="1"/>
                <rect x={positionInPixels.x > 600 ? -120 : 0} width="160" height="20" rx="4" fill="#EEEEEE"
                      stroke="#666666"/>
                <text x={positionInPixels.x > 600 ? -120 : 0} y={0} style={{fontSize: "12px"}}>
                    <tspan x={positionInPixels.x > 600 ? -110 : 10} y="14">{`Activation Date Savings`}</tspan>
                </text>
            </g>);
        }
    }] : []

    const co2_vars = reporting_data.co2
    const co2_label_keys = (Object.keys(co2_vars.baseline_chart_data).sort().concat(Object.keys(co2_vars.reporting_chart_data).sort()).concat(Object.keys(co2_vars.excluded_chart_data).sort())).sort()
    const co2_label_strings = co2_label_keys.map((k) => k.replace('|', ' - '))
    const co2_label_indexes = co2_label_keys.map((k, i) => i)
    const co2_electricity_baseline_data = co2_label_keys.map((k, i) => ({
        x: i, y: k in co2_vars.baseline_chart_data ? co2_vars.baseline_chart_data[k].electricity_corrected_float : 0
    }))
    const co2_electricity_reporting_data = co2_label_keys.map((k, i) => ({
        x: i, y: k in co2_vars.reporting_chart_data ? co2_vars.reporting_chart_data[k].electricity_corrected_float : 0
    }))
    const co2_gas_baseline_data = co2_label_keys.map((k, i) => ({
        x: i, y: k in co2_vars.baseline_chart_data ? co2_vars.baseline_chart_data[k].gas_corrected_float : 0
    }))
    const co2_gas_reporting_data = co2_label_keys.map((k, i) => ({
        x: i, y: k in co2_vars.reporting_chart_data ? co2_vars.reporting_chart_data[k].gas_corrected_float : 0
    }))
    const co2_heat_baseline_data = co2_label_keys.map((k, i) => ({
        x: i, y: k in co2_vars.baseline_chart_data ? co2_vars.baseline_chart_data[k].heat_corrected_float : 0
    }))
    const co2_heat_reporting_data = co2_label_keys.map((k, i) => ({
        x: i, y: k in co2_vars.reporting_chart_data ? co2_vars.reporting_chart_data[k].heat_corrected_float : 0
    }))
    const co2_cold_baseline_data = co2_label_keys.map((k, i) => ({
        x: i, y: k in co2_vars.baseline_chart_data ? co2_vars.baseline_chart_data[k].cold_corrected_float : 0
    }))
    const co2_cold_reporting_data = co2_label_keys.map((k, i) => ({
        x: i, y: k in co2_vars.reporting_chart_data ? co2_vars.reporting_chart_data[k].cold_corrected_float : 0
    }))
    const co2_savings_data = co2_label_keys.map((k, i) => ({
        x: i,
        y: k in co2_vars.reporting_chart_data && co2_vars.reporting_chart_data[k].total_absolute_savings_float >= 0 ? co2_vars.reporting_chart_data[k].total_absolute_savings_float : 0
    }))
    const co2_losses_data = co2_label_keys.map((k, i) => ({
        x: i,
        y: k in co2_vars.reporting_chart_data && co2_vars.reporting_chart_data[k].total_absolute_savings_float < 0 ? co2_vars.reporting_chart_data[k].total_absolute_savings_float : 0
    }))

    const co2_excluded_data = co2_label_keys.map((k, i) => ({
        x: i,
        y: k in co2_vars.excluded_chart_data && co2_vars.excluded_chart_data[k] ? co2_vars.excluded_chart_data[k].outlier_float : 0
    }))
    const max_co2_value = Math.max(Math.max(...Object.values(co2_vars.baseline_chart_data).map(v => v.total_corrected_float)), Math.max(...Object.values(co2_vars.reporting_chart_data).map(v => v.total_corrected_float + v.total_absolute_savings_float)))
    const co2_start_mark = co2_vars && co2_vars.activation_date ? [{
        x: co2_label_keys.findIndex(k => k.substring(0, 7) === co2_vars.activation_date.substring(0, 7)) - 0.5 + 0.032 * parseInt(co2_vars.activation_date.substring(8, 10), 10),
        y: max_co2_value * 1.1,
        customComponent: (row, positionInPixels) => {
            return (<g className="inner-inner-component">
                <line x1="0" y1="3" x2="0" y2="200" stroke="#666666" strokeWidth="1"/>
                <rect x={positionInPixels.x > 600 ? -120 : 0} width="160" height="20" rx="4" fill="#EEEEEE"
                      stroke="#666666"/>
                <text x={positionInPixels.x > 600 ? -120 : 0} y={0} style={{fontSize: "12px"}}>
                    <tspan x={positionInPixels.x > 600 ? -110 : 10} y="14">{`Activation Date Savings`}</tspan>
                </text>
            </g>);
        }
    }] : []

    const cost_savings_vars = reporting_data.cost_savings
    const cost_savings_label_keys = Object.keys(cost_savings_vars.reporting_chart_data).sort()
    const cost_savings_label_strings = cost_savings_label_keys.map((k) => k.substring(0, 7))
    const cost_savings_electricity_reporting_data = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].electricity_corrected_float : 0
    }))
    const cost_savings_electricity_predicted_data = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].electricity_predicted_float : 0
    }))
    const electricity_absolute_savings_data = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].electricity_absolute_savings_float : 0
    }))
    const gas_absolute_savings_data = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].gas_absolute_savings_float : 0
    }))
    const cost_savings_gas_predicted_data = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].gas_predicted_float : 0
    }))
    const cost_savings_gas_reporting_data = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].gas_corrected_float : 0
    }))
    const cost_savings_original_electricity_absolute_savings = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].original_electricity_absolute_savings_float : 0
    }))
    const cost_savings_original_gas_absolute_savings = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].original_gas_absolute_savings_float : 0
    }))
    const cost_savings_gas_actual_consumption = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].original_gas_corrected_float : 0
    }))
    const cost_savings_gas_predicted_consumption = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].original_gas_predicted_float : 0
    }))
    const cost_savings_electricity_actual_consumption = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].original_electricity_corrected_float : 0
    }))
    const cost_savings_electricity_predicted_consumption = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].original_electricity_predicted_float : 0
    }))

    const cost_savings_heat_reporting_data = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].heat_corrected_float : 0
    }))
    const cost_savings_heat_predicted_data = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].heat_predicted_float : 0
    }))
    const heat_absolute_savings_data = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].heat_absolute_savings_float : 0
    }))
    const cost_savings_heat_actual_consumption = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].original_heat_corrected_float : 0
    }))
    const cost_savings_heat_predicted_consumption = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].original_heat_predicted_float : 0
    }))
    const cost_savings_original_heat_absolute_savings = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].original_heat_absolute_savings_float : 0
    }))


    const cost_savings_cold_reporting_data = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].cold_corrected_float : 0
    }))
    const cost_savings_cold_predicted_data = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].cold_predicted_float : 0
    }))
    const cold_absolute_savings_data = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].cold_absolute_savings_float : 0
    }))
    const cost_savings_cold_actual_consumption = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].original_cold_corrected_float : 0
    }))
    const cost_savings_cold_predicted_consumption = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].original_cold_predicted_float : 0
    }))
    const cost_savings_original_cold_absolute_savings = cost_savings_label_keys.map((k, i) => ({
        x: i,
        y: k in cost_savings_vars.reporting_chart_data ? cost_savings_vars.reporting_chart_data[k].original_cold_absolute_savings_float : 0
    }))
    return (<div>
        <CurrentUserContext.Consumer>{me => {
            return (<Paper style={{padding: 25}}>
                {co2_vars && co2_vars.year_results && <>
                    <Typography variant="h5" key="t1">
                        Overall results
                    </Typography>
                    <Table size="small" key="t">
                        <TableHead>
                            <TableRow>
                                <TableCell key="y" style={{textAlign: "right"}}></TableCell>
                                <TableCell key="ga" style={{textAlign: "right"}}>Gas savings</TableCell>
                                <TableCell key="gr" style={{width: 55, textAlign: "right"}}></TableCell>
                                <TableCell key="ea" style={{textAlign: "right"}}>Electricity
                                    savings</TableCell>
                                <TableCell key="er" style={{width: 55, textAlign: "right"}}></TableCell>
                                <TableCell key="fa" style={{textAlign: "right"}}>Heat savings</TableCell>
                                <TableCell key="fr" style={{width: 55, textAlign: "right"}}></TableCell>
                                <TableCell key="gacold" style={{textAlign: "right"}}>Cold
                                    savings</TableCell>
                                <TableCell key="grcold" style={{width: 55, textAlign: "right"}}></TableCell>
                                {Object.keys(co2_vars.year_results).length > 0 && <>
                                    <TableCell key="ca"
                                               style={{textAlign: "right"}}>CO<sub>2</sub> reduction</TableCell>
                                    <TableCell key="cr" style={{width: 55, textAlign: "right"}}></TableCell>
                                </>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(co2_vars.year_results).sort().reverse().map(y => (<TableRow key={y}>
                                <TableCell key="y" style={{textAlign: "right"}}>{y}</TableCell>
                                <TableCell key="ga"
                                           style={{textAlign: "right"}}>{gas_vars && gas_vars.year_results && gas_vars.year_results[y] ? gas_vars.year_results[y]["abs_string"] : undefined} {gas_vars ? gas_vars.unit : undefined}</TableCell>
                                <TableCell key="gr" style={{
                                    width: 55, textAlign: "right"
                                }}>[&nbsp;{gas_vars && gas_vars.year_results && gas_vars.year_results[y] ? gas_vars.year_results[y]["rel_string"] : undefined}&nbsp;]</TableCell>
                                <TableCell key="ea"
                                           style={{textAlign: "right"}}>{electricity_vars && electricity_vars.year_results && electricity_vars.year_results[y] ? electricity_vars.year_results[y]["abs_string"] : undefined} {electricity_vars ? electricity_vars.unit : undefined}</TableCell>
                                <TableCell key="er" style={{
                                    width: 55, textAlign: "right"
                                }}>[&nbsp;{electricity_vars && electricity_vars.year_results && electricity_vars.year_results[y] ? electricity_vars.year_results[y]["rel_string"] : undefined}&nbsp;]</TableCell>
                                <TableCell key="fa"
                                           style={{textAlign: "right"}}>{heat_vars && heat_vars.year_results && heat_vars.year_results[y] ? heat_vars.year_results[y]["abs_string"] : undefined} {heat_vars ? heat_vars.unit : undefined}</TableCell>
                                <TableCell key="fr" style={{
                                    width: 55, textAlign: "right"
                                }}>[&nbsp;{heat_vars && heat_vars.year_results && heat_vars.year_results[y] ? heat_vars.year_results[y]["rel_string"] : undefined}&nbsp;]</TableCell>
                                <TableCell key="gacold"
                                           style={{textAlign: "right"}}>{cold_vars && cold_vars.year_results && cold_vars.year_results[y] ? cold_vars.year_results[y]["abs_string"] : undefined} {cold_vars ? cold_vars.unit : undefined}</TableCell>
                                <TableCell key="grcold" style={{
                                    width: 55, textAlign: "right"
                                }}>[&nbsp;{cold_vars && cold_vars.year_results && cold_vars.year_results[y] ? cold_vars.year_results[y]["rel_string"] : undefined}&nbsp;]</TableCell>
                                {Object.keys(co2_vars.year_results).length > 0 && <>
                                    <TableCell key="ca"
                                               style={{textAlign: "right"}}>{co2_vars.year_results[y]["abs_string"]} {co2_vars.unit}</TableCell>
                                    <TableCell key="cr" style={{
                                        width: 55, textAlign: "right"
                                    }}>[&nbsp;{co2_vars.year_results[y]["rel_string"]}&nbsp;]</TableCell>
                                </>}
                            </TableRow>))}
                        </TableBody>
                    </Table>
                    <Typography key="t2">&nbsp;</Typography>
                </>}
                {gas_vars.baseline_chart_data && <>
                    <Typography variant="h5">
                        Gas savings
                    </Typography>
                    <Typography>&nbsp;</Typography>
                    <XYPlot stackBy="y" width={900} height={350}
                            margin={{top: 10, right: 10, left: 150, bottom: 150}}>
                        <HorizontalGridLines style={{stroke: '#999999'}}/>
                        <XAxis tickValues={gas_label_indexes}
                               tickFormat={(v, i, scale, tickTotal) => gas_label_strings[i]}
                               tickLabelAngle={-60} height={80} style={{text: {fontSize: '12px'}}}/>
                        <YAxis tickFormat={_yTickFormatValue(gas_vars.unit)} width={140}
                               style={{text: {fontSize: '12px'}}}/>
                        <VerticalBarSeries
                            data={gas_baseline_data.map(d => ({
                                x: d.x, y: d.y, color: me.client.colorGasBaseline
                            }))}
                            colorType={"literal"}
                            color={me.client.colorGasConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                            onNearestX={(datapoint, {index}) => {
                                setCrosshairValues([datapoint])
                                setChKey(gas_label_keys[index])
                            }}
                        />
                        <VerticalBarSeries
                            data={gas_outlier_data.map(d => ({x: d.x, y: d.y, color: colorOutlier}))}
                            colorType={"literal"}
                            color={colorOutlier}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}

                        />
                        <VerticalBarSeries
                            data={gas_reporting_data.map(d => ({
                                x: d.x, y: d.y, color: me.client.colorGasConsumption
                            }))}
                            colorType={"literal"}
                            color={me.client.colorGasConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <VerticalBarSeries
                            data={gas_savings_data.map(d => ({x: d.x, y: d.y}))}
                            colorType={"literal"}
                            color={me.client.colorGasSavings}
                            fill={me.client.colorGasSavings}
                            stroke={me.client.colorGasConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <VerticalBarSeries
                            data={gas_losses_data.map(d => ({x: d.x, y: d.y}))}
                            colorType={"literal"}
                            color={me.client.colorStateError}
                            fill={me.client.colorStateError}
                            stroke={me.client.colorStateError}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <Crosshair values={crosshairValues}>
                            <div style={{
                                background: '#555555',
                                padding: '3px 5px',
                                borderRadius: '3px',
                                fontSize: '11px',
                                color: '#e0e0e0',
                                rx: '3px'
                            }}>
                                <table>
                                    <tr>
                                        <td><b>Period&nbsp;start:</b></td>
                                        <td align="right">{chKey && chKey.split("|").length > 1 ? chKey.split("|")[0].replace(/-/g, "\u00a0") : ''}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Period&nbsp;end:</b></td>
                                        <td align="right">{chKey && chKey.split("|").length > 1 ? chKey.split("|")[1].replace(/-/g, "\u00a0") : ''}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Consumption:</b></td>
                                        <td align="right">{gas_vars.reporting_chart_data[chKey] ? gas_vars.reporting_chart_data[chKey].corrected_string.replace(/ /g, "\u00a0") : gas_vars.baseline_chart_data[chKey] ? gas_vars.baseline_chart_data[chKey].corrected_string.replace(/ /g, "\u00a0") : gas_vars.excluded_chart_data[chKey] ? gas_vars.excluded_chart_data[chKey].corrected_string.replace(/ /g, "\u00a0") : gas_vars.neutralise_period_chart_data[chKey] ? gas_vars.neutralise_period_chart_data[chKey].corrected_string.replace(/ /g, "\u00a0") : ""}&nbsp;{gas_vars.unit}</td>
                                    </tr>
                                    {gas_vars.reporting_chart_data[chKey] ? <>
                                        <tr>
                                            <td><b>Savings:</b></td>
                                            <td align="right">{gas_vars.reporting_chart_data[chKey].absolute_savings_string.replace(/ /g, "\u00a0")}&nbsp;{gas_vars.unit}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Rel.Savings:</b></td>
                                            <td align="right">{gas_vars.reporting_chart_data[chKey].relative_savings_string}</td>
                                        </tr>
                                    </> : null}
                                </table>
                            </div>
                        </Crosshair>
                        <CustomSVGSeries customComponent="square" data={gas_start_mark}/>
                    </XYPlot>
                    <Typography variant="body1">
                        <svg width={200} height={14}>
                        </svg>
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: me.client.colorGasBaseline,
                                strokeWidth: 1,
                                stroke: me.client.colorGasBaseline
                            }}/>
                        </svg>
                        &nbsp;Gas baseline
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: me.client.colorGasConsumption,
                                strokeWidth: 1,
                                stroke: me.client.colorGasConsumption
                            }}/>
                        </svg>
                        &nbsp;Gas reporting period
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: me.client.colorGasSavings,
                                strokeWidth: 1,
                                stroke: me.client.colorGasConsumption
                            }}/>
                        </svg>
                        &nbsp;dnergy savings
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14}
                                  style={{fill: colorOutlier, strokeWidth: 1, stroke: colorOutlier}}/>
                        </svg>
                        &nbsp;Outliers / Excluded From baseline
                    </Typography>
                    <Typography>&nbsp;</Typography>
                    <List>
                        {gas_vars.outliers && Object.keys(gas_vars.outliers).length > 0 && <ListItem key=""><Typography>
                            Note: Some periods were statistical outliers and thus excluded from the
                            evaluation ({Object.keys(gas_vars.outliers).join(", ")})
                        </Typography></ListItem>}
                        {gas_vars.notes && gas_vars.notes.length > 0 &&
                            <ListItem key=""><Typography>{gas_vars.notes}</Typography></ListItem>}
                        {gas_notes_from_config && gas_notes_from_config.map(d => <ListItem key="">
                            <Typography>{d}</Typography></ListItem>)}
                    </List>
                    <Typography>&nbsp;</Typography>
                </>}
                {electricity_vars.baseline_chart_data && <>
                    <Typography variant="h5">
                        Electricity savings
                    </Typography>
                    <Typography>&nbsp;</Typography>
                    <XYPlot stackBy="y" width={900} height={350}
                            margin={{top: 10, right: 10, left: 150, bottom: 150}}>
                        <HorizontalGridLines style={{stroke: '#999999'}}/>
                        <XAxis tickValues={electricity_label_indexes}
                               tickFormat={(v, i, scale, tickTotal) => electricity_label_strings[i]}
                               tickLabelAngle={-60} height={80} style={{text: {fontSize: '12px'}}}/>
                        <YAxis tickFormat={_yTickFormatValue(electricity_vars.unit)} width={140}
                               style={{text: {fontSize: '12px'}}}/>
                        <VerticalBarSeries
                            data={electricity_baseline_data.map(d => ({
                                x: d.x, y: d.y, color: me.client.colorElectricityBaseline
                            }))}
                            colorType={"literal"}
                            color={me.client.colorElectricityConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                            onNearestX={(datapoint, {index}) => {
                                setCrosshairValues([datapoint])
                                setChKey(electricity_label_keys[index])
                            }}
                        />
                        <VerticalBarSeries
                            data={electricity_outlier_data.map(d => ({
                                x: d.x, y: d.y, color: colorOutlier
                            }))}
                            colorType={"literal"}
                            color={colorOutlier}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}

                        />
                        <VerticalBarSeries
                            data={electricity_reporting_data.map(d => ({
                                x: d.x, y: d.y, color: me.client.colorElectricityConsumption
                            }))}
                            colorType={"literal"}
                            color={me.client.colorElectricityConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <VerticalBarSeries
                            data={electricity_savings_data.map(d => ({x: d.x, y: d.y}))}
                            colorType={"literal"}
                            color={me.client.colorElectricitySavings}
                            fill={me.client.colorElectricitySavings}
                            stroke={me.client.colorElectricityConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <VerticalBarSeries
                            data={electricity_losses_data.map(d => ({x: d.x, y: d.y}))}
                            colorType={"literal"}
                            color={me.client.colorStateError}
                            fill={me.client.colorStateError}
                            stroke={me.client.colorStateError}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <Crosshair values={crosshairValues}>
                            <div style={{
                                background: '#555555',
                                padding: '3px 5px',
                                borderRadius: '3px',
                                fontSize: '11px',
                                color: '#e0e0e0',
                                rx: '3px'
                            }}>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td><b>Period&nbsp;start:</b></td>
                                        <td align="right">{chKey && chKey.split("|").length > 1 ? chKey.split("|")[0].replace(/-/g, "\u00a0") : ''}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Period&nbsp;end:</b></td>
                                        <td align="right">{chKey && chKey.split("|").length > 1 ? chKey.split("|")[1].replace(/-/g, "\u00a0") : ''}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Consumption:</b></td>
                                        <td align="right">{electricity_vars.reporting_chart_data[chKey] ? electricity_vars.reporting_chart_data[chKey].corrected_string.replace(/ /g, "\u00a0") : electricity_vars.baseline_chart_data[chKey] ? electricity_vars.baseline_chart_data[chKey].corrected_string.replace(/ /g, "\u00a0") : electricity_vars.excluded_chart_data[chKey] ? electricity_vars.excluded_chart_data[chKey].corrected_string.replace(/ /g, "\u00a0") : electricity_vars.neutralise_period_chart_data[chKey] ? electricity_vars.neutralise_period_chart_data[chKey].corrected_string.replace(/ /g, "\u00a0") : ""}&nbsp;{electricity_vars.unit}</td>
                                    </tr>
                                    {electricity_vars.reporting_chart_data[chKey] ? <>
                                        <tr>
                                            <td><b>Savings:</b></td>
                                            <td align="right">{electricity_vars.reporting_chart_data[chKey].absolute_savings_string.replace(/ /g, "\u00a0")}&nbsp;{electricity_vars.unit}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Rel.Savings:</b></td>
                                            <td align="right">{electricity_vars.reporting_chart_data[chKey].relative_savings_string}</td>
                                        </tr>
                                    </> : null}
                                    </tbody>
                                </table>
                            </div>
                        </Crosshair>
                        <CustomSVGSeries customComponent="square" data={electricity_start_mark}/>
                    </XYPlot>
                    <Typography variant="body1">
                        <svg width={200} height={14}>
                        </svg>
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: me.client.colorElectricityBaseline,
                                strokeWidth: 1,
                                stroke: me.client.colorElectricityBaseline
                            }}/>
                        </svg>
                        &nbsp;Electricity baseline
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: me.client.colorElectricityConsumption,
                                strokeWidth: 1,
                                stroke: me.client.colorElectricityConsumption
                            }}/>
                        </svg>
                        &nbsp;Electricity reporting period
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: me.client.colorElectricitySavings,
                                strokeWidth: 1,
                                stroke: me.client.colorElectricityConsumption
                            }}/>
                        </svg>
                        &nbsp;dnergy savings
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14}
                                  style={{fill: colorOutlier, strokeWidth: 1, stroke: colorOutlier}}/>
                        </svg>
                        &nbsp;Outliers / Excluded From baseline
                    </Typography>
                    <Typography>&nbsp;</Typography>
                </>}
                <List>
                    {electricity_vars.outliers && Object.keys(electricity_vars.outliers).length > 0 &&
                        <ListItem key=""><Typography>
                            Some periods were statistical outliers and thus excluded from the evaluation
                            ({Object.keys(electricity_vars.outliers).join(", ")})
                        </Typography></ListItem>}
                    {electricity_vars.notes && electricity_vars.notes.length > 0 &&
                        <ListItem key=""><Typography>{electricity_vars.notes}</Typography></ListItem>}
                    {electricity_notes_from_config && electricity_notes_from_config.map(d => <ListItem key="">
                        <Typography>{d}</Typography></ListItem>)}
                </List>
                {heat_vars && heat_vars.baseline_chart_data && <>
                    <Typography variant="h5">
                        heat savings
                    </Typography>
                    <Typography>&nbsp;</Typography>
                    <XYPlot stackBy="y" width={900} height={350}
                            margin={{top: 10, right: 10, left: 150, bottom: 150}}>
                        <HorizontalGridLines style={{stroke: '#999999'}}/>
                        <XAxis tickValues={heat_label_indexes}
                               tickFormat={(v, i, scale, tickTotal) => heat_label_strings[i]}
                               tickLabelAngle={-60} height={80} style={{text: {fontSize: '12px'}}}/>
                        <YAxis tickFormat={_yTickFormatValue(heat_vars.unit)} width={140}
                               style={{text: {fontSize: '12px'}}}/>
                        <VerticalBarSeries
                            data={heat_baseline_data.map(d => ({x: d.x, y: d.y, color: colorHeatBaseline}))}
                            colorType={"literal"}
                            color={colorHeatConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                            onNearestX={(datapoint, {index}) => {
                                setCrosshairValues([datapoint])
                                setChKey(heat_label_keys[index])
                            }}
                        />
                        <VerticalBarSeries
                            data={heat_outlier_data.map(d => ({x: d.x, y: d.y, color: colorOutlier}))}
                            colorType={"literal"}
                            color={colorOutlier}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}

                        />
                        <VerticalBarSeries
                            data={heat_reporting_data.map(d => ({
                                x: d.x, y: d.y, color: colorHeatConsumption
                            }))}
                            colorType={"literal"}
                            color={colorHeatConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <VerticalBarSeries
                            data={heat_savings_data.map(d => ({x: d.x, y: d.y}))}
                            colorType={"literal"}
                            color={colorHeatSavings}
                            fill={colorHeatSavings}
                            stroke={colorHeatConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <VerticalBarSeries
                            data={heat_losses_data.map(d => ({x: d.x, y: d.y}))}
                            colorType={"literal"}
                            color={me.client.colorStateError}
                            fill={me.client.colorStateError}
                            stroke={me.client.colorStateError}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <Crosshair values={crosshairValues}>
                            <div style={{
                                background: '#555555',
                                padding: '3px 5px',
                                borderRadius: '3px',
                                fontSize: '11px',
                                color: '#e0e0e0',
                                rx: '3px'
                            }}>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td><b>Period&nbsp;start:</b></td>
                                        <td align="right">{chKey && chKey.split("|").length > 1 ? chKey.split("|")[0].replace(/-/g, "\u00a0") : ''}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Period&nbsp;end:</b></td>
                                        <td align="right">{chKey && chKey.split("|").length > 1 ? chKey.split("|")[1].replace(/-/g, "\u00a0") : ''}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Consumption:</b></td>
                                        <td align="right">{heat_vars.reporting_chart_data[chKey] ? heat_vars.reporting_chart_data[chKey].corrected_string.replace(/ /g, "\u00a0") : heat_vars.baseline_chart_data[chKey] ? heat_vars.baseline_chart_data[chKey].corrected_string.replace(/ /g, "\u00a0") : heat_vars.excluded_chart_data[chKey] ? heat_vars.excluded_chart_data[chKey].corrected_string.replace(/ /g, "\u00a0") : heat_vars.neutralise_period_chart_data[chKey] ? heat_vars.neutralise_period_chart_data[chKey].corrected_string.replace(/ /g, "\u00a0") : ""}&nbsp;{heat_vars.unit}</td>
                                    </tr>
                                    {heat_vars.reporting_chart_data[chKey] ? <>
                                        <tr>
                                            <td><b>Savings:</b></td>
                                            <td align="right">{heat_vars.reporting_chart_data[chKey].absolute_savings_string.replace(/ /g, "\u00a0")}&nbsp;{heat_vars.unit}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Rel.Savings:</b></td>
                                            <td align="right">{heat_vars.reporting_chart_data[chKey].relative_savings_string}</td>
                                        </tr>
                                    </> : null}
                                    </tbody>
                                </table>
                            </div>
                        </Crosshair>
                        <CustomSVGSeries customComponent="square" data={heat_start_mark}/>
                    </XYPlot>
                    <Typography variant="body1">
                        <svg width={200} height={14}>
                        </svg>
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: colorHeatBaseline, strokeWidth: 1, stroke: colorHeatBaseline
                            }}/>
                        </svg>
                        &nbsp;Heat baseline
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: colorHeatConsumption, strokeWidth: 1, stroke: colorHeatConsumption
                            }}/>
                        </svg>
                        &nbsp;Heat reporting period
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: colorHeatSavings, strokeWidth: 1, stroke: colorHeatConsumption
                            }}/>
                        </svg>
                        &nbsp;dnergy savings
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14}
                                  style={{fill: colorOutlier, strokeWidth: 1, stroke: colorOutlier}}/>
                        </svg>
                        &nbsp;Outliers / Excluded From baseline
                    </Typography>
                    <Typography>&nbsp;</Typography>
                </>}
                <List>
                    {heat_vars && heat_vars.outliers && Object.keys(heat_vars.outliers).length > 0 &&
                        <ListItem key=""><Typography>
                            Some periods were statistical outliers and thus excluded from the evaluation
                            ({Object.keys(heat_vars.outliers).join(", ")})
                        </Typography></ListItem>}
                    {heat_vars && heat_vars.notes && heat_vars.notes.length > 0 &&
                        <ListItem key=""><Typography>{heat_vars.notes}</Typography></ListItem>}
                    {heat_notes_from_config && heat_notes_from_config.map(d => <ListItem key="">
                        <Typography>{d}</Typography></ListItem>)}
                </List>

                {cold_vars && cold_vars.baseline_chart_data && <>
                    <Typography variant="h5">
                        cold savings
                    </Typography>
                    <Typography>&nbsp;</Typography>
                    <XYPlot stackBy="y" width={900} height={350}
                            margin={{top: 10, right: 10, left: 150, bottom: 150}}>
                        <HorizontalGridLines style={{stroke: '#999999'}}/>
                        <XAxis tickValues={cold_label_indexes}
                               tickFormat={(v, i, scale, tickTotal) => cold_label_strings[i]}
                               tickLabelAngle={-60} height={80} style={{text: {fontSize: '12px'}}}/>
                        <YAxis tickFormat={_yTickFormatValue(cold_vars.unit)} width={140}
                               style={{text: {fontSize: '12px'}}}/>
                        <VerticalBarSeries
                            data={cold_baseline_data.map(d => ({x: d.x, y: d.y, color: colorColdBaseline}))}
                            colorType={"literal"}
                            color={colorColdConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                            onNearestX={(datapoint, {index}) => {
                                setCrosshairValues([datapoint])
                                setChKey(cold_label_keys[index])
                            }}
                        />
                        <VerticalBarSeries
                            data={cold_outlier_data.map(d => ({x: d.x, y: d.y, color: colorOutlier}))}
                            colorType={"literal"}
                            color={colorOutlier}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <VerticalBarSeries
                            data={cold_reporting_data.map(d => ({
                                x: d.x, y: d.y, color: colorColdConsumption
                            }))}
                            colorType={"literal"}
                            color={colorColdConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <VerticalBarSeries
                            data={cold_savings_data.map(d => ({x: d.x, y: d.y}))}
                            colorType={"literal"}
                            color={colorColdSavings}
                            fill={colorColdSavings}
                            stroke={colorColdConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <VerticalBarSeries
                            data={cold_losses_data.map(d => ({x: d.x, y: d.y}))}
                            colorType={"literal"}
                            color={me.client.colorStateError}
                            fill={me.client.colorStateError}
                            stroke={me.client.colorStateError}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <Crosshair values={crosshairValues}>
                            <div style={{
                                background: '#555555',
                                padding: '3px 5px',
                                borderRadius: '3px',
                                fontSize: '11px',
                                color: '#e0e0e0',
                                rx: '3px'
                            }}>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td><b>Period&nbsp;start:</b></td>
                                        <td align="right">{chKey && chKey.split("|").length > 1 ? chKey.split("|")[0].replace(/-/g, "\u00a0") : ''}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Period&nbsp;end:</b></td>
                                        <td align="right">{chKey && chKey.split("|").length > 1 ? chKey.split("|")[1].replace(/-/g, "\u00a0") : ''}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Consumption:</b></td>
                                        <td align="right">{cold_vars.reporting_chart_data[chKey] ? cold_vars.reporting_chart_data[chKey].corrected_string.replace(/ /g, "\u00a0") : cold_vars.baseline_chart_data[chKey] ? cold_vars.baseline_chart_data[chKey].corrected_string.replace(/ /g, "\u00a0") : cold_vars.excluded_chart_data[chKey] ? cold_vars.excluded_chart_data[chKey].corrected_string.replace(/ /g, "\u00a0") : cold_vars.neutralise_period_chart_data[chKey] ? cold_vars.neutralise_period_chart_data[chKey].corrected_string.replace(/ /g, "\u00a0") : ""}&nbsp;{cold_vars.unit}</td>
                                    </tr>
                                    {cold_vars.reporting_chart_data[chKey] ? <>
                                        <tr>
                                            <td><b>Savings:</b></td>
                                            <td align="right">{cold_vars.reporting_chart_data[chKey].absolute_savings_string.replace(/ /g, "\u00a0")}&nbsp;{cold_vars.unit}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Rel.Savings:</b></td>
                                            <td align="right">{cold_vars.reporting_chart_data[chKey].relative_savings_string}</td>
                                        </tr>
                                    </> : null}
                                    </tbody>
                                </table>
                            </div>
                        </Crosshair>
                        <CustomSVGSeries customComponent="square" data={cold_start_mark}/>
                    </XYPlot>
                    <Typography variant="body1">
                        <svg width={200} height={14}>
                        </svg>
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: colorColdBaseline,
                                strokeWidth: 1,
                                stroke: me.client.colorElectricityBaseline
                            }}/>
                        </svg>
                        &nbsp;cold baseline
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: colorColdConsumption,
                                strokeWidth: 1,
                                stroke: me.client.colorElectricityConsumption
                            }}/>
                        </svg>
                        &nbsp;cold reporting period
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: colorColdSavings,
                                strokeWidth: 1,
                                stroke: me.client.colorElectricityConsumption
                            }}/>
                        </svg>
                        &nbsp;dnergy savings
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14}
                                  style={{fill: colorOutlier, strokeWidth: 1, stroke: colorOutlier}}/>
                        </svg>
                        &nbsp;Outliers / Excluded From baseline
                    </Typography>
                    <Typography>&nbsp;</Typography>
                </>}
                <List>
                    {cold_vars && cold_vars.outliers && Object.keys(cold_vars.outliers).length > 0 &&
                        <ListItem key=""><Typography>
                            Some periods were statistical outliers and thus excluded from the evaluation
                            ({Object.keys(cold_vars.outliers).join(", ")})
                        </Typography></ListItem>}
                    {cold_vars && cold_vars.notes && cold_vars.notes.length > 0 &&
                        <ListItem key=""><Typography>{cold_vars.notes}</Typography></ListItem>}
                    {cold_notes_from_config && cold_notes_from_config.map(d => <ListItem key="">
                        <Typography>{d}</Typography></ListItem>)}
                </List>
                {Object.keys(co2_vars.reporting_chart_data).length > 0 && <>
                    <Typography>&nbsp;</Typography>
                    <Typography variant="h5">
                        CO<sub>2</sub> emission savings
                    </Typography>
                    <Typography>&nbsp;</Typography>
                    <XYPlot stackBy="y" width={900} height={350}
                            margin={{top: 10, right: 10, left: 150, bottom: 150}}>
                        <HorizontalGridLines style={{stroke: '#999999'}}/>
                        <XAxis tickValues={co2_label_indexes}
                               tickFormat={(v, i, scale, tickTotal) => co2_label_strings[i]}
                               tickLabelAngle={-60} height={80} style={{text: {fontSize: '12px'}}}/>
                        <YAxis tickFormat={_yTickFormatValue(co2_vars.unit)} width={140}
                               style={{text: {fontSize: '12px'}}}/>
                        <VerticalBarSeries
                            data={co2_electricity_baseline_data.map(d => ({
                                x: d.x, y: d.y, color: me.client.colorElectricityBaseline
                            }))}
                            colorType={"literal"}
                            color={me.client.colorElectricityConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                            onNearestX={(datapoint, {index}) => {
                                setCrosshairValues([datapoint])
                                setChKey(co2_label_keys[index])
                            }}
                        />
                        <VerticalBarSeries
                            data={co2_gas_baseline_data.map(d => ({
                                x: d.x, y: d.y, color: me.client.colorGasBaseline
                            }))}
                            colorType={"literal"}
                            color={me.client.colorGasConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <VerticalBarSeries
                            data={co2_heat_baseline_data.map(d => ({
                                x: d.x, y: d.y, color: colorHeatBaseline
                            }))}
                            colorType={"literal"}
                            color={colorHeatBaseline}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <VerticalBarSeries
                            data={co2_cold_baseline_data.map(d => ({
                                x: d.x, y: d.y, color: colorColdBaseline
                            }))}
                            colorType={"literal"}
                            color={colorColdBaseline}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <VerticalBarSeries
                            data={co2_electricity_reporting_data.map(d => ({
                                x: d.x, y: d.y, color: me.client.colorElectricityConsumption
                            }))}
                            colorType={"literal"}
                            color={me.client.colorElectricityConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <VerticalBarSeries
                            data={co2_gas_reporting_data.map(d => ({
                                x: d.x, y: d.y, color: me.client.colorGasConsumption
                            }))}
                            colorType={"literal"}
                            color={me.client.colorGasConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <VerticalBarSeries
                            data={co2_excluded_data.map(d => ({x: d.x, y: d.y, color: colorOutlier}))}
                            colorType={"literal"}
                            color={colorOutlier}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <VerticalBarSeries
                            data={co2_heat_reporting_data.map(d => ({
                                x: d.x, y: d.y, color: colorHeatConsumption
                            }))}
                            colorType={"literal"}
                            color={colorHeatConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <VerticalBarSeries
                            data={co2_cold_reporting_data.map(d => ({
                                x: d.x, y: d.y, color: colorColdConsumption
                            }))}
                            colorType={"literal"}
                            color={colorColdConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <VerticalBarSeries
                            data={co2_savings_data.map(d => ({x: d.x, y: d.y}))}
                            colorType={"literal"}
                            color={me.client.colorElectricitySavings}
                            fill={me.client.colorElectricitySavings}
                            stroke={me.client.colorElectricityConsumption}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <VerticalBarSeries
                            data={co2_losses_data.map(d => ({x: d.x, y: d.y}))}
                            colorType={"literal"}
                            color={me.client.colorStateError}
                            fill={me.client.colorStateError}
                            stroke={me.client.colorStateError}
                            barWidth={0.6}
                            getNull={(d) => d.y !== null && d.y !== undefined}
                        />
                        <Crosshair values={crosshairValues}>
                            <div style={{
                                background: '#555555',
                                padding: '3px 5px',
                                borderRadius: '3px',
                                fontSize: '11px',
                                color: '#e0e0e0',
                                rx: '3px'
                            }}>
                                <table>
                                    <tbody>
                                    <tr>
                                        <td><b>Period&nbsp;start:</b></td>
                                        <td align="right">{chKey && chKey.split("|").length > 1 ? chKey.split("|")[0].replace(/-/g, "\u00a0") : ''}</td>
                                    </tr>
                                    <tr>
                                        <td><b>Period&nbsp;end:</b></td>
                                        <td align="right">{chKey && chKey.split("|").length > 1 ? chKey.split("|")[1].replace(/-/g, "\u00a0") : ''}</td>
                                    </tr>
                                    {co2_vars.excluded_chart_data[chKey] ? <tr>
                                        <td><b>Total from all commodities:</b></td>
                                        <td align="right">{co2_vars.excluded_chart_data[chKey] && co2_vars.excluded_chart_data[chKey].outlier_string ? co2_vars.excluded_chart_data[chKey].outlier_string.replace(/ /g, "\u00a0") : ""}&nbsp;{co2_vars.unit}</td>
                                    </tr> : <>
                                        <tr>
                                            <td><b>Electricity:</b></td>
                                            <td align="right">{co2_vars.reporting_chart_data[chKey] && co2_vars.reporting_chart_data[chKey].electricity_corrected_string ? co2_vars.reporting_chart_data[chKey].electricity_corrected_string.replace(/ /g, "\u00a0") : co2_vars.baseline_chart_data[chKey] && co2_vars.baseline_chart_data[chKey].electricity_corrected_string ? co2_vars.baseline_chart_data[chKey].electricity_corrected_string.replace(/ /g, "\u00a0") : ""}&nbsp;{co2_vars.unit}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Gas:</b></td>
                                            <td align="right">{co2_vars.reporting_chart_data[chKey] && co2_vars.reporting_chart_data[chKey].gas_corrected_string ? co2_vars.reporting_chart_data[chKey].gas_corrected_string.replace(/ /g, "\u00a0") : co2_vars.baseline_chart_data[chKey] && co2_vars.baseline_chart_data[chKey].gas_corrected_string ? co2_vars.baseline_chart_data[chKey].gas_corrected_string.replace(/ /g, "\u00a0") : ""}&nbsp;{co2_vars.unit}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Heat:</b></td>
                                            <td align="right">{co2_vars.reporting_chart_data[chKey] && co2_vars.reporting_chart_data[chKey].heat_corrected_string ? co2_vars.reporting_chart_data[chKey].heat_corrected_string.replace(/ /g, "\u00a0") : co2_vars.baseline_chart_data[chKey] && co2_vars.baseline_chart_data[chKey].heat_corrected_string ? co2_vars.baseline_chart_data[chKey].heat_corrected_string.replace(/ /g, "\u00a0") : ""}&nbsp;{co2_vars.unit}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Cold:</b></td>
                                            <td align="right">{co2_vars.reporting_chart_data[chKey] && co2_vars.reporting_chart_data[chKey].cold_corrected_string ? co2_vars.reporting_chart_data[chKey].cold_corrected_string.replace(/ /g, "\u00a0") : co2_vars.baseline_chart_data[chKey] && co2_vars.baseline_chart_data[chKey].cold_corrected_string ? co2_vars.baseline_chart_data[chKey].cold_corrected_string.replace(/ /g, "\u00a0") : ""}&nbsp;{co2_vars.unit}</td>
                                        </tr>
                                    </>}
                                    {co2_vars.reporting_chart_data[chKey] ? <>
                                        <tr>
                                            <td><b>Savings:</b></td>
                                            <td align="right">{co2_vars.reporting_chart_data[chKey].total_absolute_savings_string.replace(/ /g, "\u00a0")}&nbsp;{co2_vars.unit}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Rel.Savings:</b></td>
                                            <td align="right">{co2_vars.reporting_chart_data[chKey].total_relative_savings_string}</td>
                                        </tr>
                                    </> : null}
                                    </tbody>
                                </table>
                            </div>
                        </Crosshair>
                        <CustomSVGSeries customComponent="square" data={co2_start_mark}/>
                    </XYPlot>
                    <Typography variant="body1">
                        <svg width={50} height={14}>
                        </svg>
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: me.client.colorElectricityBaseline,
                                strokeWidth: 1,
                                stroke: me.client.colorElectricityBaseline
                            }}/>
                        </svg>
                        &nbsp;Electricity baseline
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: me.client.colorGasBaseline,
                                strokeWidth: 1,
                                stroke: me.client.colorGasBaseline
                            }}/>
                        </svg>
                        &nbsp;Gas baseline
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: colorHeatBaseline, strokeWidth: 1, stroke: me.client.colorGasBaseline
                            }}/>
                        </svg>
                        &nbsp;Heat baseline
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: colorColdBaseline, strokeWidth: 1, stroke: me.client.colorGasBaseline
                            }}/>
                        </svg>
                        &nbsp;Cold baseline
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: me.client.colorElectricityConsumption,
                                strokeWidth: 1,
                                stroke: me.client.colorElectricityConsumption
                            }}/>
                        </svg>
                        &nbsp;Electricity reporting period
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: me.client.colorGasConsumption,
                                strokeWidth: 1,
                                stroke: me.client.colorGasConsumption
                            }}/>
                        </svg>
                        &nbsp;Gas reporting period
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: colorHeatConsumption,
                                strokeWidth: 1,
                                stroke: me.client.colorGasConsumption
                            }}/>
                        </svg>
                        &nbsp;Heat reporting period
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: colorColdConsumption,
                                strokeWidth: 1,
                                stroke: me.client.colorGasConsumption
                            }}/>
                        </svg>
                        &nbsp;Cold reporting period
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <svg width={14} height={14}>
                            <rect x={0} y={0} width={14} height={14} style={{
                                fill: me.client.colorElectricitySavings,
                                strokeWidth: 1,
                                stroke: me.client.colorElectricityConsumption
                            }}/>
                        </svg>
                        &nbsp;dnergy savings
                    </Typography>
                    <Typography>&nbsp;</Typography>
                    <List>
                        {gas_vars.outliers && Object.keys(gas_vars.outliers).length > 0 && <ListItem key=""><Typography>
                            For gas, some periods were statistical outliers and thus excluded from the
                            evaluation ({Object.keys(gas_vars.outliers).join(", ")})
                        </Typography></ListItem>}
                        {electricity_vars.outliers && Object.keys(electricity_vars.outliers).length > 0 &&
                            <ListItem key=""><Typography>
                                For electricity, some periods were statistical outliers and thus excluded
                                from the evaluation ({Object.keys(electricity_vars.outliers).join(", ")})
                            </Typography></ListItem>}
                        {co2_vars.notes && co2_vars.notes.length > 0 &&
                            <ListItem key=""><Typography>{co2_vars.notes}</Typography></ListItem>}
                    </List>
                </>}
                {cost_savings_electricity_reporting_data && cost_savings_label_strings && electricity_reporting_data && cost_savings_electricity_reporting_data && cost_savings_electricity_predicted_data && cost_savings_original_electricity_absolute_savings && cost_savings_electricity_reporting_data.map(v => v.y).every(v => v !== undefined) && <>
                    <Typography variant="h5" key="t1">
                        Cost savings electricity
                    </Typography>
                    <Table size="small" key="t">
                        <TableHead>
                            <TableRow>
                                <TableCell key="y" style={{textAlign: "right"}}></TableCell>
                                <TableCell key="ga" style={{textAlign: "right"}}>Actual consumption
                                    [kWh]</TableCell>
                                <TableCell key="gr" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="ha" style={{textAlign: "right"}}>Actual cost [€]</TableCell>
                                <TableCell key="hr" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="ea" style={{textAlign: "right"}}>Adjusted baseline
                                    [kWh]</TableCell>
                                <TableCell key="er" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="fa" style={{textAlign: "right"}}>Adjusted baseline
                                    [€]</TableCell>
                                <TableCell key="fr" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="ia" style={{textAlign: "right"}}>dnergy savings
                                    [kWh]</TableCell>
                                <TableCell key="ir" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="ka" style={{textAlign: "right"}}>dnergy savings
                                    [€]</TableCell>
                                <TableCell key="kr" style={{width: 35, textAlign: "right"}}></TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cost_savings_electricity_reporting_data.filter(d => d.y !== 0).filter(d => d.y !== null).map(d => (
                                <TableRow key={d.x}>
                                    <TableCell key="y"
                                               style={{textAlign: "right"}}>{cost_savings_label_strings[d.x]}</TableCell>
                                    <TableCell key="ga"
                                               style={{textAlign: "right"}}>{cost_savings_electricity_actual_consumption.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="gr" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="ha"
                                               style={{textAlign: "right"}}>{cost_savings_electricity_reporting_data.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="hr" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="ea"
                                               style={{textAlign: "right"}}>{cost_savings_electricity_predicted_consumption.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="er" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="fa"
                                               style={{textAlign: "right"}}>{cost_savings_electricity_predicted_data.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="fr" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="ia"
                                               style={{textAlign: "right"}}>{cost_savings_original_electricity_absolute_savings.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="ir" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="ka"
                                               style={{textAlign: "right"}}>{electricity_absolute_savings_data.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="kr" style={{width: 35, textAlign: "right"}}></TableCell>
                                </TableRow>))}
                        </TableBody>
                    </Table>
                    <Typography key="t2">&nbsp;</Typography>
                </>}

                {cost_savings_label_strings && cost_savings_gas_reporting_data && cost_savings_gas_predicted_data && cost_savings_original_gas_absolute_savings && gas_absolute_savings_data && cost_savings_gas_reporting_data.map(v => v.y).every(v => v !== undefined) && <>
                    <Typography variant="h5" key="t1">
                        Cost savings gas
                    </Typography>
                    <Table size="small" key="t">
                        <TableHead>
                            <TableRow>
                                <TableCell key="y" style={{textAlign: "right"}}></TableCell>
                                <TableCell key="ga" style={{textAlign: "right"}}>Actual consumption
                                    [kWh]</TableCell>
                                <TableCell key="gr" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="ha" style={{textAlign: "right"}}>Actual cost [€]</TableCell>
                                <TableCell key="hr" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="ea" style={{textAlign: "right"}}>Adjusted baseline
                                    [kWh]</TableCell>
                                <TableCell key="er" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="fa" style={{textAlign: "right"}}>Adjusted baseline
                                    [€]</TableCell>
                                <TableCell key="fr" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="ia" style={{textAlign: "right"}}>dnergy savings
                                    [kWh]</TableCell>
                                <TableCell key="ir" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="ka" style={{textAlign: "right"}}>dnergy savings
                                    [€]</TableCell>
                                <TableCell key="kr" style={{width: 35, textAlign: "right"}}></TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cost_savings_gas_reporting_data.filter(d => d.y !== 0).filter(d => d.y !== null).map(d => (
                                <TableRow key={d.x}>
                                    <TableCell key="y"
                                               style={{textAlign: "right"}}>{cost_savings_label_strings[d.x]}</TableCell>
                                    <TableCell key="ga"
                                               style={{textAlign: "right"}}>{cost_savings_gas_actual_consumption.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="gr" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="ha"
                                               style={{textAlign: "right"}}>{cost_savings_gas_reporting_data.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="hr" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="ea"
                                               style={{textAlign: "right"}}>{cost_savings_gas_predicted_consumption.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="er" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="fa"
                                               style={{textAlign: "right"}}>{cost_savings_gas_predicted_data.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="fr" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="ia"
                                               style={{textAlign: "right"}}>{cost_savings_original_gas_absolute_savings.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="ir" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="ka"
                                               style={{textAlign: "right"}}>{gas_absolute_savings_data.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="kr" style={{width: 35, textAlign: "right"}}></TableCell>
                                </TableRow>))}
                        </TableBody>
                    </Table>
                    <Typography key="t2">&nbsp;</Typography>
                </>}

                {cost_savings_label_strings && cost_savings_heat_reporting_data && cost_savings_heat_predicted_data && cost_savings_original_heat_absolute_savings && heat_absolute_savings_data && cost_savings_heat_reporting_data.map(v => v.y).every(v => v !== undefined) && <>
                    <Typography variant="h5" key="t1">
                        Cost savings heat
                    </Typography>
                    <Table size="small" key="t">
                        <TableHead>
                            <TableRow>
                                <TableCell key="y" style={{textAlign: "right"}}></TableCell>
                                <TableCell key="ga" style={{textAlign: "right"}}>Actual consumption
                                    [kWh]</TableCell>
                                <TableCell key="gr" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="ha" style={{textAlign: "right"}}>Actual cost [€]</TableCell>
                                <TableCell key="hr" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="ea" style={{textAlign: "right"}}>Adjusted baseline
                                    [kWh]</TableCell>
                                <TableCell key="er" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="fa" style={{textAlign: "right"}}>Adjusted baseline
                                    [€]</TableCell>
                                <TableCell key="fr" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="ia" style={{textAlign: "right"}}>dnergy savings
                                    [kWh]</TableCell>
                                <TableCell key="ir" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="ka" style={{textAlign: "right"}}>dnergy savings
                                    [€]</TableCell>
                                <TableCell key="kr" style={{width: 35, textAlign: "right"}}></TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cost_savings_heat_reporting_data.filter(d => d.y !== 0).filter(d => d.y !== null).map(d => (
                                <TableRow key={d.x}>
                                    <TableCell key="y"
                                               style={{textAlign: "right"}}>{cost_savings_label_strings[d.x]}</TableCell>
                                    <TableCell key="ga"
                                               style={{textAlign: "right"}}>{cost_savings_heat_actual_consumption.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="gr" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="ha"
                                               style={{textAlign: "right"}}>{cost_savings_heat_reporting_data.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="hr" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="ea"
                                               style={{textAlign: "right"}}>{cost_savings_heat_predicted_consumption.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="er" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="fa"
                                               style={{textAlign: "right"}}>{cost_savings_heat_predicted_data.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="fr" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="ia"
                                               style={{textAlign: "right"}}>{cost_savings_original_heat_absolute_savings.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="ir" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="ka"
                                               style={{textAlign: "right"}}>{heat_absolute_savings_data.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="kr" style={{width: 35, textAlign: "right"}}></TableCell>
                                </TableRow>))}
                        </TableBody>
                    </Table>
                    <Typography key="t2">&nbsp;</Typography>
                </>}


                {cost_savings_label_strings && cost_savings_cold_reporting_data && cost_savings_cold_predicted_data && cost_savings_original_cold_absolute_savings && cold_absolute_savings_data && cost_savings_cold_reporting_data.map(v => v.y).every(v => v !== undefined) && <>
                    <Typography variant="h5" key="t1">
                        Cost savings cold
                    </Typography>
                    <Table size="small" key="t">
                        <TableHead>
                            <TableRow>
                                <TableCell key="y" style={{textAlign: "right"}}></TableCell>
                                <TableCell key="ga" style={{textAlign: "right"}}>Actual consumption
                                    [kWh]</TableCell>
                                <TableCell key="gr" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="ha" style={{textAlign: "right"}}>Actual cost [€]</TableCell>
                                <TableCell key="hr" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="ea" style={{textAlign: "right"}}>Adjusted baseline
                                    [kWh]</TableCell>
                                <TableCell key="er" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="fa" style={{textAlign: "right"}}>Adjusted baseline
                                    [€]</TableCell>
                                <TableCell key="fr" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="ia" style={{textAlign: "right"}}>dnergy savings
                                    [kWh]</TableCell>
                                <TableCell key="ir" style={{width: 35, textAlign: "right"}}></TableCell>
                                <TableCell key="ka" style={{textAlign: "right"}}>dnergy savings
                                    [€]</TableCell>
                                <TableCell key="kr" style={{width: 35, textAlign: "right"}}></TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cost_savings_cold_reporting_data.filter(d => d.y !== 0).filter(d => d.y !== null).map(d => (
                                <TableRow key={d.x}>
                                    <TableCell key="y"
                                               style={{textAlign: "right"}}>{cost_savings_label_strings[d.x]}</TableCell>
                                    <TableCell key="ga"
                                               style={{textAlign: "right"}}>{cost_savings_cold_actual_consumption.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="gr" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="ha"
                                               style={{textAlign: "right"}}>{cost_savings_cold_reporting_data.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="hr" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="ea"
                                               style={{textAlign: "right"}}>{cost_savings_cold_predicted_consumption.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="er" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="fa"
                                               style={{textAlign: "right"}}>{cost_savings_cold_predicted_data.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="fr" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="ia"
                                               style={{textAlign: "right"}}>{cost_savings_original_cold_absolute_savings.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="ir" style={{width: 35, textAlign: "right"}}></TableCell>
                                    <TableCell key="ka"
                                               style={{textAlign: "right"}}>{cold_absolute_savings_data.map(r => r.y)[d.x]}</TableCell>
                                    <TableCell key="kr" style={{width: 35, textAlign: "right"}}></TableCell>
                                </TableRow>))}
                        </TableBody>
                    </Table>
                    <Typography key="t2">&nbsp;</Typography>
                </>}


            </Paper>)
        }}
        </CurrentUserContext.Consumer>
    </div>);
}

export default ResultsTabPreviewSavings;
