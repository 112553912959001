import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import {graphql} from 'react-apollo'
import gql from 'graphql-tag'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import _ from 'lodash';
import * as d3 from 'd3-time'
import moment from 'moment'
import {TimeEvent, TimeRange, TimeSeries} from "pondjs"
import {
    ChartContainer,
    ChartRow,
    Charts,
    EventMarker,
    Legend,
    LineChart,
    Resizable,
    styler,
    TimeRangeMarker,
    YAxis
} from "react-timeseries-charts"

const sequentialColors = ['Set3', 'Paired', 'Blues', 'BuGn', 'BuPu', 'GnBu', 'Greens', 'Greys', 'Oranges', 'OrRd', 'PuBu', 'PuBuGn', 'PuRd', 'Purples', 'RdPu', 'Reds', 'YlGn', 'YlGnBu', 'YlOrBr', 'YlOrRd'];

const getColorStyler = (columnNames, colorCodeOrMetricName) => {
    const colorStyler = (colorCodeOrMetricName === "*")
        ? styler(columnNames, "Set1")
        : sequentialColors.indexOf(colorCodeOrMetricName) > -1
            ? styler(columnNames, colorCodeOrMetricName)
            : styler(columnNames.map(name => ({
                key: name,
                color: colorCodeOrMetricName,
            })));
    return colorStyler;
}

const styles = theme => ({
    wrapped: {
        flexWrap: 'wrap',
    },
})

const axisStyle = {
    labelFont: "montserrat",
    labelColor: "#BBBBBB",
}
const getFunctionFromTags = (tags) => (
    tags.filter(tag => tag.key === "function")[0] && tags.filter(tag => tag.key === "function")[0].value
)
const getRwcFromTags = (tags) => (
    tags.filter(tag => tag.key === "rwc")[0] && tags.filter(tag => tag.key === "rwc")[0].value
)
const getCreatorFromTags = (tags) => (
    tags.filter(tag => tag.key === "creator")[0] && tags.filter(tag => tag.key === "creator")[0].value
)


const getTimewindowFromTags = (tags) => (
    tags.filter(tag => tag.key === "timewindow")[0] && tags.filter(tag => tag.key === "timewindow")[0].value
)
const getMinValueFromData = (data, axisName) => (
    (data && axisName)
        ? (axisName === "status")
            ? 0.0
            : Math.min(
                ...data.filter(smg => smg.axis === axisName).map(
                    smg => Math.min(
                        ...smg.series.columns().map(columnName => smg.series.min(columnName))
                    )
                )
            )
        : 0.0
)
const getMaxValueFromData = (data, axisName) => (
    (data && axisName)
        ? (axisName === "status")
            ? Math.max(1.0, Math.max(
                ...data.filter(smg => smg.axis === axisName).map(
                    smg => Math.max(
                        ...smg.series.columns().map(columnName => smg.series.max(columnName))
                    )
                )
            ))
            : Math.max(
                ...data.filter(smg => smg.axis === axisName).map(
                    smg => Math.max(
                        ...smg.series.columns().map(columnName => smg.series.max(columnName))
                    )
                )
            )
        : 1.0
)

const getTimeseriesFromPayload = (id, name, payload, timeRange) => (
    (payload == null) || (timeRange == null)
        ? null
        : new TimeSeries({
            name: name,
            columns: ["time", id],
            points: Object.keys(payload)
                .map(k => parseInt(k, 10))
                .filter(k => k >= timeRange.begin().getTime() && k <= timeRange.end().getTime())
                .sort()
                .map(k => [k, payload[k]])
        }).clean(id)
)
const getTimeseriesFromData = (payload, timewindow, ts_id, ts_name, chartTimeRange, historyTimeRange, forecastTimeRange) => (
    TimeSeries.timeSeriesListMerge({
        name: "data",
        seriesList: (timewindow === "past")
            ? [
                getTimeseriesFromPayload(ts_id, ts_name, payload, historyTimeRange),
                new TimeSeries({
                    name: ts_name,
                    columns: ["time", ts_id],
                    points: [[moment(historyTimeRange.end()).add(1, 'seconds').toDate().getTime(), NaN]],
                }),
            ].filter(ts => ts !== null)
            : (timewindow === "future")
                ? [
                    new TimeSeries({
                        name: ts_name,
                        columns: ["time", ts_id],
                        points: [[moment(historyTimeRange.end()).add(2, 'seconds').toDate().getTime(), NaN]],
                    }),
                    getTimeseriesFromPayload(ts_id, ts_name, payload, forecastTimeRange),
                ].filter(ts => ts !== null)
                : [
                    getTimeseriesFromPayload(ts_id, ts_name, payload, chartTimeRange),
                ].filter(ts => ts !== null)
    })
)

const getTimeseriesIdFromSystemMetric = (systemMetric) => (
    systemMetric.system
        ? systemMetric.system.id + '###' + systemMetric.systemMetricTemplate.id
        : systemMetric.space.id + '###' + systemMetric.systemMetricTemplate.id + '###' + getFunctionFromTags(systemMetric.systemMetricTags)
)

const getSpaceDisplayName = (systemMetric) => (
    systemMetric.space ? systemMetric.space.displayName : undefined
)
const convertPayload = (payload, sourceUnit, targetUnit) => (
    (sourceUnit === "K" && targetUnit === "degC")
        ? Array.from(new Map(Object.keys(payload).map(k => [k, payload[k] - 273.15]))).reduce((acc, [k, v]) => Object.assign(acc, {[k]: v}), {})
        : payload
)

function mapSystemMetricGroup(systemMetrics, systemMetricGroup, chartTimeRange, historyTimeRange, forecastTimeRange, modelMetrics) {
    const filteredSystemMetrics = Array.from(systemMetrics)
        .filter(sm => sm.systemMetricTemplate.name === systemMetricGroup.systemMetricTemplateName)
        .filter(sm => (systemMetricGroup.systemMetricTagFunction === undefined || systemMetricGroup.systemMetricTagFunction === getFunctionFromTags(sm.systemMetricTags)))
        .filter(sm => (systemMetricGroup.systemMetricTagRwc === undefined || systemMetricGroup.systemMetricTagRwc === getRwcFromTags(sm.systemMetricTags)))
        .filter(sm => (systemMetricGroup.systemMetricTagCreator === undefined || systemMetricGroup.systemMetricTagCreator === getCreatorFromTags(sm.systemMetricTags)))
        .filter(sm => (systemMetricGroup.spaceDisplayname === undefined || systemMetricGroup.spaceDisplayname === getSpaceDisplayName(sm)))
        .filter(sm => ('payload' in sm && sm.payload !== null && Object.keys(sm.payload).length > 0));

    var series = null;
    const systemMetricSeries = TimeSeries.timeSeriesListMerge({
        name: "data", seriesList: filteredSystemMetrics.map(sm => getTimeseriesFromData(
            sm.payload, getTimewindowFromTags(sm.systemMetricTags), getTimeseriesIdFromSystemMetric(sm), sm.systemMetricTemplate.name,
            chartTimeRange, historyTimeRange, forecastTimeRange))
    });

    // if a modelMetricTemplateName is filled out in the chart definition, we're making a few assumptions on how the SM and MM need to integrate
    // i.e. that the ModelMetric that is found is a forecast for the SystemMetric that is part of the same systemMetricGroups
    if (systemMetricGroup.modelMetricTemplateName) {
        const filteredModelMetrics = Array.from(modelMetrics)
            .filter(mm => mm.modelMetricTemplate.name === systemMetricGroup.modelMetricTemplateName)
            .filter(mm => ('payload' in mm && mm.payload !== null && Object.keys(mm.payload).length > 0 && mm.modelMetricTemplate.name in mm.payload))

        const modelMetricSeries = TimeSeries.timeSeriesListMerge({
            name: "data", seriesList: filteredModelMetrics.map(mm => getTimeseriesFromData(
                convertPayload(mm.payload[mm.modelMetricTemplate.name], mm.modelMetricTemplate.unitString, filteredSystemMetrics[0].systemMetricTemplate.metric.defaultDisplayUnitString),
                getTimewindowFromTags(mm.modelMetricTags), getTimeseriesIdFromSystemMetric(filteredSystemMetrics[0]), filteredSystemMetrics[0].systemMetricTemplate.name,
                chartTimeRange, historyTimeRange, forecastTimeRange))
        });
        series = TimeSeries.timeSeriesListMerge({
            name: "data",
            seriesList: [systemMetricSeries, modelMetricSeries]
        });
    } else {
        series = systemMetricSeries;
    }

    const styler = getColorStyler(series.columns(), systemMetricGroup.color);
    styler.columnNames.forEach(name => {
        styler.columnStyles[name]["fontSize"] = "small";
        styler.columnStyles[name]["width"] = 1;
        styler.columnStyles[name]["dashed"] = systemMetricGroup.dashed === true;
    });
    const legendStyler = getColorStyler(series.columns(), systemMetricGroup.color);
    legendStyler.columnNames.forEach(name => {
        legendStyler.columnStyles[name]["width"] = 3;
        legendStyler.columnStyles[name]["dashed"] = systemMetricGroup.dashed === true;
    });

    var legend_map = new Map();
    if (systemMetricGroup.addToLegend) {
        for (const systemMetric of filteredSystemMetrics) {
            const column_id = getTimeseriesIdFromSystemMetric(systemMetric);
            if (series.columns().indexOf(column_id) > -1) {
                legend_map.set(column_id, {
                    key: getTimeseriesIdFromSystemMetric(systemMetric),
                    label: (systemMetric.system
                        ? systemMetric.system.displayName
                        : systemMetric.space
                            ? systemMetric.space.displayName
                            : systemMetric.waterCircuit
                                ? systemMetric.waterCircuit.displayName
                                : systemMetric.systemMetricTemplate.displayName),
                })
            }
        }
    }
    const legend_categories = Array.from(legend_map.values());

    return ({
        displayName: (filteredSystemMetrics.length > 0 ? filteredSystemMetrics[0].systemMetricTemplate.displayName : "") + (systemMetricGroup.systemMetricTagFunction ? " [" + systemMetricGroup.systemMetricTagFunction + "]" : ""),
        name: systemMetricGroup.systemMetricTemplateName,
        displayUnitString: filteredSystemMetrics.length > 0 ? filteredSystemMetrics[0].systemMetricTemplate.metric.defaultDisplayUnitString : null,
        columns: series.columns(),
        legend_categories: legend_categories,
        series: series,
        style: styler,
        legendStyle: legendStyler,
        axis: systemMetricGroup.axis,
        interactive: true,
    });
}

const getData = (systemMetricGroups, systemMetrics, modelMetrics, chartTimeRange, historyTimeRange, forecastTimeRange) => (
    systemMetricGroups.map((systemMetricGroup) => {
        return mapSystemMetricGroup(systemMetrics, systemMetricGroup, chartTimeRange, historyTimeRange, forecastTimeRange, modelMetrics);
    })
)

const getLegendFromData = (data, grouping) => {
    if (grouping === "system") {
        let flattened_array = [];
        data.filter(ts => ts.legend_categories.length > 0).forEach(ts => {
            ts.legend_categories.forEach(lc => {
                flattened_array.push({
                    system_metric_id: lc.key,
                    system_displayName: lc.label,
                    metric_name: ts.name,
                    metric_displayName: ts.displayName,
                    metric_unit: ts.displayUnitString,
                    legendStyle: Object.assign(ts.legendStyle.columnStyles[lc.key], {key: lc.key}),
                })
            })
        })
        const group_names = Array.from(new Set(flattened_array.map(l => l.system_displayName)))
        return group_names.map(gn => ({
            name: gn,
            displayName: gn,
            legendStyle: styler(flattened_array.filter(l => l.system_displayName === gn).map(gi => gi.legendStyle)),
            legend_categories: flattened_array.filter(l => l.system_displayName === gn).map(gi => ({
                key: gi.system_metric_id,
                label: gi.metric_displayName,
                displayUnitString: gi.metric_unit,
            }))
        }))
    }


    return data.filter(ts => ts.legend_categories.length > 0).map(ts => ({
        name: ts.name,
        displayName: ts.displayName,
        legendStyle: ts.legendStyle,
        legend_categories: ts.legend_categories.map(lc => ({
            key: lc.key,
            label: lc.label,
            displayUnitString: ts.displayUnitString,
        }))
    }));
}

const getTimeRange = dateTime => (
    new TimeRange([moment(dateTime).subtract(11, 'days').toDate(), moment(dateTime).add(3, 'days').toDate()])
)
const getVisibleTimeRange = dateTime => (
    new TimeRange([moment(dateTime).subtract(4, 'days').toDate(), moment(dateTime).add(3, 'days').toDate()])
)
const getHistoryTimeRange = dateTime => (
    new TimeRange([moment(dateTime).subtract(11, 'days').toDate(), moment(dateTime).toDate()])
)
const getForecastTimeRange = dateTime => (
    new TimeRange([moment(dateTime).toDate(), moment(dateTime).add(3, 'days').toDate()])
)

class CrossHairs extends React.Component {
    render() {
        const {x, y} = this.props;
        const style = {pointerEvents: "none", stroke: "#ccc"};
        if (!x || !y) {
            return <g/>;
        } else {
            return (
                <g>
                    <line style={style} x1={0} y1={y} x2={this.props.width} y2={y}/>
                    <line style={style} x1={x} y1={0} x2={x} y2={this.props.height}/>
                </g>
            );
        }
    }
}

class ChartHistoryWithForecast extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tracker: null,
            trackerValues: null,
            x: null,
            y: null,
            timeMarker: null,
            dashboardDateTime: moment().toDate(),
            chartTimeRange: getTimeRange(moment().toDate()),
            visibleChartTimeRange: getVisibleTimeRange(moment().toDate()),
            historyTimeRange: getHistoryTimeRange(moment().toDate()),
            forecastTimeRange: getForecastTimeRange(moment().toDate()),
            data: null,
            legend: null,
            error: false
        };
    }

    componentDidMount() {
        this.handleProps(this.props);
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps, this.props)) {
            this.handleProps(this.props);
        }
    }

    handleProps = (props) => {
        if (!props.data.error && !props.data.loading) {
            const systemMetrics = props.data.building.systemMetrics;
            const allSystemMetricsHaveSystemMetricTemplate = systemMetrics.every(sm => sm.systemMetricTemplate != null);
            if (!allSystemMetricsHaveSystemMetricTemplate) {
                this.setState({error: true});
                return;
            }

            let modelMetrics = [];
            if (props.data.building.activeControl) {
                if (!props.data.building.activeControl.controlModels) {
                    this.setState({error: true});
                    return;
                }

                if (props.data.building.activeControl.controlModels.length === 1) {
                    modelMetrics = props.data.building.activeControl.controlModels[0].modelMetrics;
                }
            }


            const dashboardDateTime = props.data.building.lastDataPushDateTime;
            const chartTimeRange = getTimeRange(dashboardDateTime);
            const visibleChartTimeRange = getVisibleTimeRange(dashboardDateTime);
            const historyTimeRange = getHistoryTimeRange(dashboardDateTime);
            const forecastTimeRange = getForecastTimeRange(dashboardDateTime);

            this.setState({
                dashboardDateTime,
                chartTimeRange,
                visibleChartTimeRange,
                historyTimeRange,
                forecastTimeRange
            });


            const data = getData(props.systemMetricGroups, systemMetrics, modelMetrics, chartTimeRange, historyTimeRange, forecastTimeRange);
            const legend = getLegendFromData(data, props.legendGrouping);
            this.setState({data, legend});

        }
    }

    handleTrackerChanged = tracker => {
        if (tracker == null) {
            this.setState({tracker, trackerValues: null, timeMarker: null, x: null, y: null});
        } else {
            this.setState({tracker});
            const trackerValueObject = this.state.data
                .filter(smg => smg.columns.length > 0)
                .map(smg => smg.series.atTime(tracker).toJSON()['data'])
                .reduce((obj, item) => Object.assign(obj, item), {})
            this.setState({trackerValues: trackerValueObject});
            this.setState({
                timeMarker: {
                    event: new TimeEvent(tracker, {y: 0}),
                    datetime: moment(tracker).format("YYYY-MM-DD HH:mm"),
                }
            });
        }
    }

    handleMouseMove = (x, y) => {
        this.setState({x, y});
    }

    setVisibleChartTimeRange = (chartTimeRange) => {
        this.setState({
            visibleChartTimeRange: new TimeRange([
                moment.max(moment(this.state.chartTimeRange.begin()), moment(chartTimeRange.begin())).toDate(),
                moment.min(moment(this.state.chartTimeRange.end()), moment(chartTimeRange.end())).toDate()
            ])
        })
    }

    render() {
        if (this.state.error || this.props.data.error) {
            return (<Grid container><Grid item style={{height: "200px"}}><Typography gutterBottom>Error fetching chart
                data!</Typography></Grid></Grid>);
        }

        if (!this.state.data || this.props.data.loading || !this.props.data.building) {
            return (<Grid container><Grid item style={{height: "200px"}}><CircularProgress size={50} color="secondary"/></Grid></Grid>);
        }

        if (!this.props.data.building.systemMetrics || this.props.data.building.systemMetrics.length === 0) {
            return (<Grid container><Grid item style={{height: "200px"}}><Typography gutterBottom>No data
                available</Typography></Grid></Grid>)
        } else {
            return (
                <Grid container spacing={4}>
                    <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                        <Resizable>
                            <ChartContainer timeRange={this.state.visibleChartTimeRange} width={800}
                                            TimeAxisStyle={axisStyle}
                                            format={date => (d3.timeHour(date) < date ? moment(date).format("H[h]mm") : d3.timeDay(date) < date ? moment(date).format("H[h]") : moment(date).format("ddd DD"))}
                                            onMouseMove={(x, y) => this.handleMouseMove(x, y)}
                                            onTrackerChanged={tracker => this.handleTrackerChanged(tracker)}
                                            enablePanZoom={true}
                                            onTimeRangeChanged={this.setVisibleChartTimeRange}
                            >
                                <ChartRow height="200">
                                    {this.props.systemMetricAxes && this.props.systemMetricAxes.map(a => (
                                        <YAxis key={a.name} id={a.name} label={a.label} width="60" style={axisStyle}
                                               min={a.min ? a.min : getMinValueFromData(this.state.data, a.name)}
                                               max={a.max ? a.max : getMaxValueFromData(this.state.data, a.name)}
                                               format={a.name === "status" ? ".0f" : ".2s"}
                                               tickCount={a.name === "status" ? 2 : null}
                                        />
                                    ))}
                                    <Charts>
                                        <TimeRangeMarker
                                            timerange={this.state.forecastTimeRange}
                                            style={{fill: "#D3D3D3", opacity: 0.25}}
                                            timeScale={() => null}
                                            width={0}
                                            height={0}
                                        />
                                        {this.state.data && this.state.data.map(ts => (
                                            <LineChart
                                                key={ts.name}
                                                axis={ts.axis}
                                                breakLine={true}
                                                series={ts.series}
                                                columns={ts.columns}
                                                style={ts.style}
                                                highlight={ts.interactive ? this.state.highlight : null}
                                                onHighlightChange={ts.interactive ? highlight => this.setState({highlight}) : null}
                                                selection={ts.interactive ? this.state.selection : null}
                                                onSelectionChange={ts.interactive ? selection => this.setState({selection}) : null}
                                            />
                                        ))}
                                        {this.state.timeMarker
                                            ? ([
                                                <CrossHairs key="crossHairs" x={this.state.x} y={this.state.y}/>
                                                ,
                                                <EventMarker
                                                    key="timeMarker"
                                                    type="flag"
                                                    column="y"
                                                    axis={this.props.systemMetricAxes[0].name}
                                                    event={this.state.timeMarker.event}
                                                    info={[{label: "Time", value: this.state.timeMarker.datetime}]}
                                                    infoStyle={{
                                                        fill: "white",
                                                        opacity: 0.7,
                                                        stroke: "#999",
                                                        pointerEvents: "none"
                                                    }}
                                                    infoWidth={140}
                                                    markerRadius={0}
                                                    infoTimeFormat=""
                                                    stemStyle={{
                                                        stroke: "#999",
                                                        strokeWidth: 0,
                                                        cursor: "crosshair",
                                                        pointerEvents: "none"
                                                    }}
                                                />
                                            ]) : (
                                                <CrossHairs x={this.state.x} y={this.state.y}/>
                                            )}
                                    </Charts>
                                </ChartRow>
                            </ChartContainer>
                        </Resizable>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                        {this.state.legend && this.state.legend.map(lg => (
                            <div key={lg.name} style={{width: "100%"}}>
                                <Typography variant="subtitle1">{lg.displayName}</Typography>
                                <Legend className={this.props.classes.wrapped}
                                        key={lg.name}
                                        type="line"
                                        align="left"
                                        stack={true}
                                        style={lg.legendStyle}
                                        categories={lg.legend_categories.map(lc => ({
                                            key: lc.key,
                                            label: lc.label + (
                                                this.state.trackerValues && !isNaN(this.state.trackerValues[lc.key])
                                                    ? ' ['
                                                    + parseFloat(this.state.trackerValues[lc.key]).toFixed(0)
                                                    + (lc.displayUnitString && lc.displayUnitString !== "dimensionless" ? " " + lc.displayUnitString : "")
                                                    + ']'
                                                    : ''
                                            )
                                        }))}
                                        highlight={this.state.highlight}
                                        onHighlightChange={highlight => this.setState({highlight})}
                                        selection={this.state.selection}
                                        onSelectionChange={selection => this.setState({selection})}
                                />
                            </div>
                        ))}
                    </Grid>
                </Grid>
            )
        }
    }
}

const ChartHistoryWithForecastQuery = gql`
query ChartHistoryWithForecastQuery ($projectID: String!, $systemMetricTemplateNames: [String!]!, $modelMetricTemplateNames: [String!]!) {
    building( where: { projectID: $projectID }) {
        id
        projectID
        
        activeControl {
            id
            controlModels (where:{controlModelType: "greybox"}) {
                id
                modelMetrics (where:{modelMetricTemplate:{name_in:$modelMetricTemplateNames}}) {
                    id
                    payload
                    zone {
                        id
                        displayName
                    }
                    modelMetricTags {
                        id
                        key
                        value
                    }
                    modelMetricTemplate {
                        id
                        name
                        unitString
                        systemMetricTemplates {
                            id
                            name
                            displayName
                            description
                            metric {
                                id
                                name
                                displayName
                                description
                                defaultDisplayUnitString
                            }
                        }
                    }
                }
            }
        }
        systemMetrics (where:{
            AND: [
                {
                    OR: [
                        {system:{building:{projectID:$projectID}}},
                        {space:{slug:"space_0000", building:{projectID:$projectID}}}
                        ]
                },{
                    systemMetricTemplate:{name_in:$systemMetricTemplateNames},
                    payloadType: "timeseries"
                },{
                    systemMetricTags_none: { key: "hide" value: "true" }
                }
              ]}) {
            id
            payload
            payloadType
            system {
                id
                displayName
            }
            space {
                id
                displayName
            }
            systemMetricTags {
                id
                key
                value
            }
            systemMetricTemplate {
                id
                name
                displayName
                description
                metric {
                    id
                    name
                    displayName
                    description
                    defaultDisplayUnitString
                }
            }
        }
    }
}`;

export default graphql(ChartHistoryWithForecastQuery, {
    options: (props) => ({
        variables: {
            projectID: props.projectID,
            systemTemplateNames: props.systemMetricGroups.map(smg => smg.systemTemplateName),
            systemMetricTemplateNames: props.systemMetricGroups.map(smg => smg.systemMetricTemplateName).filter(mmtn => mmtn && mmtn.length > 0),
            modelMetricTemplateNames: props.systemMetricGroups.map(smg => smg.modelMetricTemplateName).filter(mmtn => mmtn && mmtn.length > 0),
        }
    })
})(withStyles(styles)(ChartHistoryWithForecast));
