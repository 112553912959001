import React, {useEffect} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Divider from '@material-ui/core/Divider';
import {useLocation, withRouter} from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import {useSelectedBuilding} from "../contexts/SelectedBuilding";

const styles = theme => ({
    whiteTextField: {
        backgroundColor: '#FFFFFF',
        marginTop: '8px',
        marginBottom: '8px',
    },
    whiteMenu: {},
    whiteInput: {
        paddingLeft: '10px',
        backgroundColor: '#FFFFFF',
        hover: {
            height: '0px',
            backgroundColor: '#FFFFFF',
        }
    },
    whiteSelect: {
        backgroundColor: '#FFFFFF',
    },
});

const FormBuildingSelection = props => {
    const {classes, me} = props;
    const {selectedBuilding, setSelectedProjectID} = useSelectedBuilding();
    const location = useLocation();

    const handleSelectedBuildingIdChange = (projectID) => {
        setSelectedProjectID(projectID);
        const pathArray = location.pathname.split('/');
        if (pathArray[1] === 'buildings' && pathArray.length >= 3) {
            pathArray[2] = projectID;
            props.history.push(pathArray.join('/'));
        } else {
            props.history.push(`/buildings/${projectID}/info`);
        }
    };
    useEffect(() => {
        if (selectedBuilding == null) {
            setSelectedProjectID(me.client.buildings[0].projectID);
        }
    }, [props.data, me.client.buildings, selectedBuilding, setSelectedProjectID]);
    return (
        <div>
            <TextField
                id="select-building"
                select
                value={selectedBuilding?.projectID ? selectedBuilding.projectID : me.client.buildings[0].projectID}
                onChange={(event) => handleSelectedBuildingIdChange(event.target.value)}
                margin="normal"
                className={classes.whiteTextField}
                SelectProps={{
                    MenuProps: {
                        className: classes.whiteMenu,
                    },
                }}
                inputProps={{
                    className: classes.whiteInput,
                }}
            >
                {me.client.buildings.sort((a, b) => a.displayName.localeCompare(b.displayName)).map(option => (
                    <MenuItem key={option.projectID} value={option.projectID}>
                        {option.displayName}
                    </MenuItem>
                ))}

                {me.client.buildings.length && me.externalBuildingRoles.length ? (
                    <Divider></Divider>
                ) : null}

                {me.externalBuildingRoles.sort((a, b) => a.building.displayName.localeCompare(b.building.displayName))
                    .map(b => (
                        <MenuItem key={b.building.projectID} value={b.building.projectID}>
                            {b.building.displayName}
                        </MenuItem>
                    ))
                }
            </TextField>

        </div>
    )
}


export default withStyles(styles)(withRouter(FormBuildingSelection));
