import React from 'react'

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Grid from '@material-ui/core/Grid';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Typography from '@material-ui/core/Typography';

import { CurrentUserContext } from '../contexts/CurrentUser'

class ProjectOverviewDeployments extends React.Component {
  render() {
    return (
      <CurrentUserContext.Consumer>{ me => { return (
        (this.props.data.error || this.props.data.loading || !this.props.data.me ) ? (
          <Typography></Typography>
        ) : (
          <Grid container spacing={4} hidden={!(me.role === "OPERATOR" || me.role === "ADMIN" || me.role === "SUPERADMIN")}>
            <Grid item xs={12}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Project</TableCell>
                    <TableCell>Gateway</TableCell>
                    <TableCell>Raw</TableCell>
                    <TableCell>Spaces</TableCell>
                    <TableCell>Systems</TableCell>
                    <TableCell>Circuits</TableCell>
                    <TableCell>Metrics</TableCell>
                    <TableCell>Models</TableCell>
                    <TableCell>Activation</TableCell>
                    <TableCell>Control</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.data.me.client.buildings
                  .sort((a, b) => a.projectID.localeCompare(b.projectID))
                  .map(building => (
                    <TableRow key={building.id}>
                      <TableCell>{building.projectID}</TableCell>
                      <TableCell>
                        { building.projectID === "kbc_leutiens"
                          ? "ClientVPN "
                          : ["bnp_lighthouse", "infrax_dilbeek", "leasinvest_crescent", "sodexo_plaine15", "wattson_bzh", "wattson_prp", "wattson_slb", "wattson_tka"].includes(building.projectID)
                          ? "Zerotier "
                          : ["kbc_leutiens_bc", "kbc_leutiens_de", "gceurope_haasrode_bad", "gceurope_haasrode_btc", "ucll_haasrode_01", "ucll_haasrode_02", "ucll_haasrode_03", "ucll_haasrode_04"].includes(building.projectID)
                          ? "--Copy-- "
                          : building.gateways.length > 0
                          ? building.gateways.map(gateway => (
                            gateway.uuid
                            ? "Balena "
                            : "Unknown "))
                          : ""
                        }
                      </TableCell>
                      <TableCell style={{backgroundColor:["infrax_dilbeek", "gceurope_haasrode", "kbc_leutiens", "ucll_haasrode"].includes(building.projectID) ? "#FFFFFF" : building.rawTrajectories && building.rawTrajectories.length > 0 ? Object.keys(building.rawTrajectories[0].payload ? building.rawTrajectories[0].payload : {}).length > 800 ? "#66FF66" : "#FFCC66" : "#FF6666"}}>{building.rawTrajectories && building.rawTrajectories.length > 0 ? "ok (" + Object.keys(building.rawTrajectories[0].payload ? building.rawTrajectories[0].payload : {}).length + ")" : "-"}</TableCell>
                      <TableCell style={{backgroundColor:["infrax_dilbeek", "gceurope_haasrode", "kbc_leutiens", "ucll_haasrode"].includes(building.projectID) ? "#FFFFFF" : building.spaces.length > 1 ? "#66FF66" : "#FF6666"}}>{building.spaces.length === 0 ? "-" : building.spaces.length}</TableCell>
                      <TableCell style={{backgroundColor:["infrax_dilbeek", "gceurope_haasrode", "kbc_leutiens", "ucll_haasrode"].includes(building.projectID) ? "#FFFFFF" : building.systems.length > 7 ? "#66FF66" : "#FF6666"}}>{building.systems.length === 0 ? "-" : building.systems.length}</TableCell>
                      <TableCell style={{backgroundColor:["infrax_dilbeek", "gceurope_haasrode", "kbc_leutiens", "ucll_haasrode"].includes(building.projectID) ? "#FFFFFF" : building.waterCircuits.length > 0 ? "#66FF66" : "#FF6666"}}>{building.waterCircuits.length === 0 ? "-" : building.waterCircuits.length}</TableCell>
                      <TableCell style={{backgroundColor:["infrax_dilbeek", "gceurope_haasrode", "kbc_leutiens", "ucll_haasrode"].includes(building.projectID) ? "#FFFFFF" : building.systemMetrics && building.systemMetrics.length > 0 ? Object.keys(building.systemMetrics[0].payload ? building.systemMetrics[0].payload : {}).length > 800 ? "#66FF66" : "#FFCC66" : "#FF6666"}}>{building.systemMetrics && building.systemMetrics.length > 0 ? "ok (" + Object.keys(building.systemMetrics[0].payload ? building.systemMetrics[0].payload : {}).length + ")" : "-"}</TableCell>
                      <TableCell style={{backgroundColor:["infrax_dilbeek", "gceurope_haasrode", "kbc_leutiens", "ucll_haasrode"].includes(building.projectID) ? "#FFFFFF" : building.controlModels.length > 0 ? "#66FF66" : "#FF6666"}}>{building.controlModels.length === 0 ? "-" : building.controlModels.length}</TableCell>
                      <TableCell style={{backgroundColor:["infrax_dilbeek", "gceurope_haasrode", "kbc_leutiens", "ucll_haasrode"].includes(building.projectID) ? "#FFFFFF" : building.activationDate ? "#66FF66" : "#FF6666"}}>{building.activationDate}</TableCell>
                      <TableCell style={{backgroundColor:["infrax_dilbeek", "gceurope_haasrode", "kbc_leutiens", "ucll_haasrode"].includes(building.projectID) ? "#FFFFFF" : building.activationDate ? "#66FF66" : "#FF6666"}}>{building.activeControl && building.activeControl.controlModels.length && building.activeControl.controlModels[0].controlModelType ? building.activeControl.controlModels[0].controlModelType : ""}</TableCell>
                      <TableCell style={{backgroundColor:["infrax_dilbeek", "gceurope_haasrode", "kbc_leutiens", "ucll_haasrode"].includes(building.projectID) ? "#FFFFFF" : building.controlStatus !== building.controlStatusSetpoint ? "#FF6666" : building.controlStatus === "Active" ? "#66FF66" : "#FFCC66"}}>{building.controlStatus}/{building.controlStatusSetpoint}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        )
      )}}
      </CurrentUserContext.Consumer>
    )
  }
}

const getCurrentUser = gql`
query getCurrentUser {
  me {
    id
    email
    name
    role
    externalBuildingRoles {
      building {
        id
        projectID
        displayName
        activationDate
        lastDataPushDateTime
        controlStatus
        controlStatusSetpoint
        location {
            id
            city
            latitude
            longitude
        }
        image {
            id
            file {
                id
                url
              }
        }
        gateways {
          id
          uuid
        }
        spaces {
          id
        }
        systems {
          id
        }
        waterCircuits {
          id
        }
        controlModels {
          id
          controlModelType
        }
        activeControl {
          id
          controlModels {
            id
            controlModelType
          }
        }
        systemMetrics(last:1) {
          id
          payload
        }
        rawTrajectories(last:1) {
          id
          payload
        }
      }
    }
    
    client {
      id
      displayName
      logo {
        id
        file {
          id
          url
        }
      }
      buildings {
        id
        projectID
        displayName
        activationDate
        lastDataPushDateTime
        controlStatus
        controlStatusSetpoint
        location {
          id
          city
          latitude
          longitude
        }
        image {
          id
          file {
            id
            url
          }
        }
        gateways {
          id
          uuid
        }
        spaces {
          id
        }
        systems {
          id
        }
        waterCircuits {
          id
        }
        controlModels {
          id
          controlModelType
        }
        activeControl {
          id
          controlModels {
            id
            controlModelType
          }
        }
        systemMetrics(last:1 where:{systemMetricTemplate:{name:"space_temperature_air"}}) {
          id
          payload
        }
        rawTrajectories(last:1) {
          id
          payload
        }
      }
      users {
        id
        name
        email
        role
      }
    }
  }
}`

export default graphql(getCurrentUser)(ProjectOverviewDeployments);
