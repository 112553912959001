import React, {Component} from 'react'

import {graphql} from 'react-apollo'
import gql from 'graphql-tag'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress';

import ChartHistoryId from './ChartHistoryId';

class ChartHistoryOutgoingWaterCircuits extends Component {
    render() {
        if (this.props.data.error) {
            return (<Typography gutterBottom>Error fetching chart data!</Typography>)
        }
        if (this.props.data.loading) {
            return (<CircularProgress size={50} color="secondary"/>)
        }
        if (!this.props.data.systems || this.props.data.systems.length === 0) {
            return (<Typography gutterBottom>No data available</Typography>)
        } else {
            return (
                this.props.data.systems.map((system, index) => (
                    <div key={system.id}>
                        <Paper style={{padding: 25}}>
                            <Typography variant="subtitle1">{system.displayName}</Typography>
                            <Typography>&nbsp;</Typography>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ChartHistoryId
                                        projectID={this.props.projectID}
                                        systemMetricIds={[].concat(...system.outgoingWaterCircuits.map(c => c.systemMetrics.map(sm => sm.id)))}
                                        systemMetricGroups={[
                                            {
                                                "systemMetricTemplateName": "wc_power_thermal",
                                                "systemMetricTagFunction": "sensor",
                                                "axis": "power_thermal",
                                                "color": "*",
                                                "addToLegend": true,
                                            },
                                            {
                                                "systemMetricTemplateName": "wc_temperature_water_supply",
                                                "systemMetricTagFunction": "sensor",
                                                "axis": "temperature_water_supply",
                                                "color": "Paired",
                                                "addToLegend": true,
                                            },
                                        ]}
                                        systemMetricAxes={[
                                            {
                                                "name": "power_thermal",
                                                "label": "Thermal power [kW]",
                                            },
                                            {
                                                "name": "temperature_water_supply",
                                                "label": "Water temperature [°C]",
                                            },
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        <Typography>&nbsp;</Typography>
                    </div>
                ))
            )
        }
    }
}

const ChartHistoryOutgoingWaterCircuitsQuery = gql`
query ChartHistoryOutgoingWaterCircuitsQuery ($projectID: String!, $systemTemplateNames: [String!]!, $systemMetricTemplateNames: [String!]!) {
  systems (
    systemTemplateNames: $systemTemplateNames
    projectID: $projectID
  ) {
    id
    slug
    displayName
    systemTemplate {
      id
      name
      displayName
    }
    outgoingWaterCircuits {
      id
      slug
      displayName
      systemMetrics (where: {
        systemMetricTemplate: { name_in: $systemMetricTemplateNames },
        systemMetricTags_none: { key: "hide" value: "true" }
      }) {
        id
      }
    }
  }
}`;

export default graphql(ChartHistoryOutgoingWaterCircuitsQuery, {
    options: (props, state) => ({
        variables: {
            projectID: props.projectID,
            systemTemplateNames: props.systemTemplateNames,
            systemMetricTemplateNames: props.systemMetricTemplateNames,
        }
    })
})(ChartHistoryOutgoingWaterCircuits);
