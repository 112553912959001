import React from 'react'

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Grid from '@material-ui/core/Grid';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Typography from '@material-ui/core/Typography';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import { CurrentUserContext } from '../contexts/CurrentUser'

const getLinkColor = (state, client) => (
  state
  ? client.colorStateOk
  : client.colorStateError
)

const ProjectOverviewFeatures = props => {
  return (
    <CurrentUserContext.Consumer>{ me => {
      const features = (props.data.error || props.data.loading || !props.data.me)
          ? []
          : props.data.me.client.buildings.reduce(
              (fl, b) => (fl.concat(b.buildingFeatures.map(bf => bf.feature).filter(ff => fl.findIndex(f => (f.id === ff.id)) < 0))),
              []
          );

      const getCategoryValue = (feature) => {
        const categoryTag = feature.featureTags && feature.featureTags.find(t => t.key === "category");
        return categoryTag ? categoryTag.value : undefined;
      };

      features.sort((a, b) => {
        const aCategory = getCategoryValue(a);
        const bCategory = getCategoryValue(b);

        if (aCategory === undefined && bCategory === undefined) return 0;
        if (aCategory === undefined) return 1;
        if (bCategory === undefined) return -1;

        const categoryComparison = aCategory.localeCompare(bCategory);
        return categoryComparison === 0 ? a.name.localeCompare(b.name) : categoryComparison;
      });

      return (
        (props.data.error || props.data.loading || !props.data.me ) ? (
          <Typography></Typography>
        ) : (
          <Grid container spacing={4} hidden={!(me.role === "OPERATOR" || me.role === "ADMIN" || me.role === "SUPERADMIN")}>
            <Grid item xs={12}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell key="projectID">Project</TableCell>
                    {features.map(f => (
                      <TableCell key={f.id}>{f.name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.data.me.client.buildings
                  .sort((a, b) => a.projectID.localeCompare(b.projectID))
                  .map(building => (
                    <TableRow key={building.id}>
                      <TableCell key="projectID">{building.projectID}</TableCell>
                      {features.map(f => (
                        building.buildingFeatures.findIndex(bf => bf.feature.id === f.id) >= 0 ? (
                          <TableCell key={f.id}><a href={"/buildings/"+ building.projectID +"/datainput/features?fid=" + f.id}><RadioButtonCheckedIcon style={{ color: getLinkColor(building.buildingFeatures.find(bf => bf.feature.id === f.id).active, props.data.me.client) }} /></a></TableCell>
                        ) : (
                          <TableCell key={f.id}><RadioButtonUncheckedIcon style={{ color: props.data.me.client.colorStateInactive }} /></TableCell>
                        )
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        )
      )
    }}
    </CurrentUserContext.Consumer>
  )
}

const getCurrentUser = gql`
query getCurrentUser {
  me {
    id
    email
    name
    role
    externalBuildingRoles {
      building {
        id
        projectID
        displayName
        buildingFeatures {
          id
          active
          config
          state
          createdAt
          updatedAt
          feature {
            id
            name
            featureTags {
              id
              key
              value
            }
          }
        }
      }
    }
    client {
      id
      displayName
      colorStateOk
      colorStateError
      colorStateInactive
      logo {
        id
        file {
          id
          url
        }
      }
      buildings {
        id
        projectID
        displayName
        buildingFeatures {
          id
          active
          config
          state
          createdAt
          updatedAt
          feature {
            id
            name
            featureTags {
              id
              key
              value
            }
          }
        }
      }
      users {
        id
        name
        email
        role
      }
    }
  }
}`

export default graphql(getCurrentUser)(ProjectOverviewFeatures);
