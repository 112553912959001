import React from 'react'
import {graphql} from 'react-apollo'
import gql from 'graphql-tag'
import {withRouter} from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';

export const CurrentUserContext = React.createContext();

const CurrentUserProvider = withRouter((props) => {
    const redirectToLandingPage = () => {
        props.history.push("/");
        props.history.go();
    };
    if (props.data.error) {
        console.log("error fetching `me`", props.data.error);
        return redirectToLandingPage();
    }
    if (props.data.loading) {
        return (<CircularProgress size={50} color="secondary"/>);
    }
    if (!props.data.me) {
        return redirectToLandingPage();
    }
    return (
        <CurrentUserContext.Provider value={props.data.me}>
            {props.children}
        </CurrentUserContext.Provider>
    )
})

const getCurrentUser = gql`
query getCurrentUser {
    me {
        id
        role
        client {
            id
            colorStateActive
            colorStateInactive
            colorStateActivating
            colorStateDeactivating
            colorStateUndefined
            colorStateError
            colorGasConsumption
            colorGasBaseline
            colorGasConfidence
            colorGasSavings
            colorGasSavingsStroke
            colorElectricityConsumption
            colorElectricityBaseline
            colorElectricityConfidence
            colorElectricitySavings
            colorElectricitySavingsStroke
            colorCO2Savings
            colorCO2SavingsStroke
            colorWaterConsumption
        }
    }
}`

export default graphql(getCurrentUser)(CurrentUserProvider);
