import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag'

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';

import ConfigSystemMetricEdit from './ConfigSystemMetricEdit'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ConfigWaterCircuit = props => {
  const { projectID, waterCircuitId, parentRefetch } = props

  const [ displayName, setDisplayName ] = useState("")
  const [ displayNameEditState, setDisplayNameEditState ] = useState(false)
  const [ selectedSourceSystemIds, setSourceSystemIds ] = useState([])
  const [ selectedDestinationSystemIds, setDestinationSystemIds ] = useState([])
  const [ selectedSystemMetricTemplateId, setSystemMetricTemplateId ] = useState("---")

  const { data, loading, error, refetch } = useQuery(getWaterCircuitData, {
    variables: { waterCircuitId, projectID },
    onCompleted: data => {
      data.waterCircuit.displayName && (data.waterCircuit.displayName.length > 0) && setDisplayName(data.waterCircuit.displayName);
      !(data.waterCircuit.displayName && (data.waterCircuit.displayName.length > 0)) && setDisplayNameEditState(true);
      data.waterCircuit.sourceSystems && setSourceSystemIds(data.waterCircuit.sourceSystems.map(system=>system.id));
      data.waterCircuit.destinationSystems && setDestinationSystemIds(data.waterCircuit.destinationSystems.map(system=>system.id));
    }
  })

  const [ deleteWaterCircuitMutation ] = useMutation(deleteWaterCircuit)
  const [ updateDisplayNameMutation ] = useMutation(updateDisplayName)
  const [ updateWaterCircuitSourceSystemsMutation ] = useMutation(updateWaterCircuitSourceSystems)
  const [ updateWaterCircuitDestinationSystemsMutation ] = useMutation(updateWaterCircuitDestinationSystems)
  const [ createSystemMetricOnWaterCircuitMutation ] = useMutation(createSystemMetricOnWaterCircuit)

  if (loading) return (<Grid><CircularProgress size={30} color="secondary" /></Grid>)
  if (error) return (<Grid><Typography gutterBottom>Error fetching WaterCircuit data!</Typography></Grid>)
  if (!data.waterCircuit) return (<Typography gutterBottom>No WaterCircuit Found!</Typography>)

  const waterCircuit = data.waterCircuit
  const buildingSystems = data.systems
  const systemMetricTags = data.systemMetricTags

  const systemMetricTagCreatorConfigwizardId = systemMetricTags.filter(t => (t.key==="creator" && t.value==="configwizard")).map(t => (t.id))[0]

  const enableDisplayNameEdit = async () => { await setDisplayNameEditState(true) }
  const disableDisplayNameEdit = async () => { await setDisplayNameEditState(false) }

  const handleDisplayNameUpdate = async () => {
    await updateDisplayNameMutation({ variables: { waterCircuitId, displayName }})
    await disableDisplayNameEdit()
    await refetch()
  }

  const handleSelectedSourceSystemIdsChange = async (event) => {
    await setSourceSystemIds(event.target.value);
    await updateWaterCircuitSourceSystemsMutation({
      variables: {
        waterCircuitId: waterCircuitId,
        sourceSystems: event.target.value.map(id => ({"id": id})),
    }});
    await refetch()
  };

  const handleSelectedDestinationSystemIdsChange = async (event) => {
    await setDestinationSystemIds(event.target.value);
    await updateWaterCircuitDestinationSystemsMutation({
      variables: {
        waterCircuitId: waterCircuitId,
        destinationSystems: event.target.value.map(id => ({"id": id})),
    }});
    await refetch()
  };

  const handleWaterCircuitDelete = async () => {
    await deleteWaterCircuitMutation({ variables: { waterCircuitId }})
    await parentRefetch()
  }

  const handleSystemMetricAdd = async () => {
    if (selectedSystemMetricTemplateId !== "---") {
      await createSystemMetricOnWaterCircuitMutation({
        variables: {
          projectID: projectID,
          waterCircuitId: waterCircuitId,
          systemMetricTemplateId: selectedSystemMetricTemplateId,
          payload:{},
          userInput:{},
          aggregation:"",
          systemMetricTags:[
            {"id": systemMetricTagCreatorConfigwizardId},
            {"id": waterCircuit.systemTemplate.systemMetricTemplates.find(smt => (smt.id === selectedSystemMetricTemplateId)).possibleFunctionTags[0].id}
          ],
          rawTrajectories:[],
          profileContextCreateOneWithoutSystemMetricConversionsInput: null,
      }})
      await refetch()
    }
  }

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" >{displayName}</Typography>
        <Typography variant="h6" style={{flex:1}}>&nbsp;</Typography>
        <Hidden xlDown={displayNameEditState}>
          <Icon onClick={enableDisplayNameEdit}>edit</Icon>
        </Hidden>
        <Hidden xlDown={!displayNameEditState}>
          <Input
            id="displayName-input"
            placeholder={waterCircuit.systemTemplate.displayName + " Name"}
            value={displayName}
            onChange={(event) => {setDisplayName(event.target.value)}}
          />
          &nbsp;&nbsp;&nbsp;
          <Button size="small" variant="contained" color="primary" onClick={handleDisplayNameUpdate}>
            Update&nbsp;&nbsp;&nbsp;<Icon>save</Icon>
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button size="small" variant="contained" color="primary" onClick={disableDisplayNameEdit}>
            Cancel&nbsp;&nbsp;&nbsp;<Icon>cancel</Icon>
          </Button>
        </Hidden>
        <Typography variant="h6">&nbsp;</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" gutterBottom>Source System(s)</Typography>
              </TableCell>
              <TableCell>
                <Select
                  multiple
                  value={selectedSourceSystemIds}
                  onChange={handleSelectedSourceSystemIdsChange}
                  input={<Input id="source-system-multiple" />}
                  renderValue={selected => buildingSystems.filter(system => selected.indexOf(system.id)>-1).map(system=>system.displayName).join(', ')}
                  MenuProps={MenuProps}
                >
                  {buildingSystems.map(system => (
                    <MenuItem key={system.id} value={system.id}>
                      <Checkbox checked={selectedSourceSystemIds.indexOf(system.id)>-1} />
                      <ListItemText primary={system.displayName} />
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" gutterBottom>Destination System(s)</Typography>
              </TableCell>
              <TableCell>
                <Select
                  multiple
                  value={selectedDestinationSystemIds}
                  onChange={handleSelectedDestinationSystemIdsChange}
                  input={<Input id="destination-system-multiple" />}
                  renderValue={selected => buildingSystems.filter(system => selected.indexOf(system.id)>-1).map(system=>system.displayName).join(', ')}
                  MenuProps={MenuProps}
                >
                  {buildingSystems.map(system => (
                    <MenuItem key={system.id} value={system.id}>
                      <Checkbox checked={selectedDestinationSystemIds.indexOf(system.id)>-1} />
                      <ListItemText primary={system.displayName} />
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" gutterBottom>Configure New Metric</Typography>
              </TableCell>
              <TableCell>
                <TextField
                  id="select-system-metric-template"
                  select
                  value={selectedSystemMetricTemplateId}
                  onChange={(event) => {setSystemMetricTemplateId(event.target.value)}}
                  margin="normal"
                >
                  <MenuItem key={"---"} value={"---"}>
                    -- Select Metric to Add --
                  </MenuItem>
                  {waterCircuit.systemTemplate.systemMetricTemplates
                    .filter(smt => (smt.possibleFunctionTags && (smt.possibleFunctionTags.length > 0)))
                    .sort((a, b) => (a.guiOrder - b.guiOrder || (a.displayName > b.displayName ? 1 : -1)))
                    .map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.displayName}
                      </MenuItem>
                  ))}
                </TextField>
              </TableCell>
              <TableCell align="right">
                <Button size="small" variant="contained" color="primary" onClick={handleSystemMetricAdd}>
                  <Icon>add</Icon>&nbsp;&nbsp;&nbsp;Add&nbsp;
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </AccordionDetails>
      <Divider />
      <AccordionDetails>
        <Table>
          <TableBody>
            {Array.from(waterCircuit.systemMetrics)
              .sort((a, b) => (a.systemMetricTemplate.guiOrder - b.systemMetricTemplate.guiOrder || (a.systemMetricTemplate.displayName > b.systemMetricTemplate.displayName ? 1 : -1)))
              .map((systemMetric, index) => {
              return ((systemMetric.systemMetricTags.findIndex(t => t.key === "function") > -1)
              ? (
                <ConfigSystemMetricEdit
                  key={systemMetric.id}
                  projectID={projectID}
                  systemMetricId={systemMetric.id}
                  parentRefetch={refetch} />
              ) : ( null ))
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <Button size="small" variant="contained" color="primary" onClick={handleWaterCircuitDelete} >
          <Icon>delete</Icon>&nbsp;&nbsp;&nbsp;Delete&nbsp;{waterCircuit.systemTemplate.displayName}&nbsp;"{waterCircuit.displayName}"&nbsp;
        </Button>
      </AccordionActions>
    </Accordion>
  );
}

const deleteWaterCircuit = gql`
mutation deleteWaterCircuit($waterCircuitId: ID!) {
  deleteWaterCircuit(
    id: $waterCircuitId
  ) {
    id
  }
}`;

const updateDisplayName = gql`
mutation updateDisplayName($waterCircuitId: ID!, $displayName: String!) {
  updateWaterCircuitDisplayName (
    id: $waterCircuitId
    displayName: $displayName
  ) { id displayName }
}`;


const updateWaterCircuitSourceSystems = gql`
mutation updateWaterCircuitSourceSystems($waterCircuitId: ID!, $sourceSystems: [SystemWhereUniqueInput!]) {
  updateWaterCircuitSourceSystems (
    id: $waterCircuitId
    sourceSystems: $sourceSystems
  ) {
    id
    displayName
    sourceSystems {
      id
      displayName
    }
  }
}`;

const updateWaterCircuitDestinationSystems = gql`
mutation updateWaterCircuitDestinationSystems($waterCircuitId: ID!, $destinationSystems: [SystemWhereUniqueInput!]) {
  updateWaterCircuitDestinationSystems (
    id: $waterCircuitId
    destinationSystems: $destinationSystems
  ) {
    id
    displayName
    destinationSystems {
      id
      displayName
    }
  }
}`;

const createSystemMetricOnWaterCircuit = gql`
mutation createSystemMetricOnWaterCircuit($projectID: String!, $waterCircuitId: ID!, $systemMetricTemplateId: ID!, $payload: Json!, $userInput:Json!, $aggregation:String!, $systemMetricTags: [SystemMetricTagWhereUniqueInput!], $rawTrajectories: [RawTrajectoryWhereUniqueInput!], $profileContextCreateOneWithoutSystemMetricConversionsInput: ProfileContextCreateOneWithoutSystemMetricConversionsInput) {
  createSystemMetricOnWaterCircuit(
    projectID: $projectID
    waterCircuitId: $waterCircuitId
    systemMetricTemplateId: $systemMetricTemplateId
    payload: $payload
    userInput: $userInput
    aggregation: $aggregation
    systemMetricTags: $systemMetricTags
    rawTrajectories: $rawTrajectories
    profileContextCreateOneWithoutSystemMetricConversionsInput: $profileContextCreateOneWithoutSystemMetricConversionsInput
    ) { id }
}`;

const getWaterCircuitData = gql`
query updateCache($waterCircuitId: ID!, $projectID: String!) {
  systemMetricTags {id key value}
  systems (projectID: $projectID) {
    id
    displayName
    slug
  }
  waterCircuit (id: $waterCircuitId) {
    id
    displayName
    slug
    systemTemplate {
      id
      name
      displayName
      systemMetricTemplates (where: {level:0}) {
        id
        name
        displayName
        guiOrder
        possibleFunctionTags {id key value}
      }
    }
    sourceSystems {
      id
      displayName
      slug
    }
    destinationSystems {
      id
      displayName
      slug
    }
    systemMetrics (where: {systemMetricTemplate: {level:0} systemMetricTags_some:{key:"creator", value:"configwizard"}}) {
      id
      userInput
      payload
      systemMetricTags {id key value}
      systemMetricTemplate {
        id
        name
        displayName
        guiOrder
        possibleFunctionTags {id key value}
      }
    }
  }
}`;

export default ConfigWaterCircuit