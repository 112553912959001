import React from 'react'

import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles'
import createPalette from '@material-ui/core/styles/createPalette'
import createTypography from '@material-ui/core/styles/createTypography'
import DefaultTheme from '../DefaultTheme';

import {graphql} from 'react-apollo';
import {flowRight as compose} from 'lodash';
import {useQuery} from '@apollo/react-hooks';
import gql from 'graphql-tag';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Grid from '@material-ui/core/Grid';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import SaveIcon from '@material-ui/icons/Save';
import MailIcon from '@material-ui/icons/Mail';
import DeleteIcon from '@material-ui/icons/Delete';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DoneIcon from '@material-ui/icons/Done';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';

import UploadClientLogo from '../components/UploadClientLogo';
import {CurrentUserContext} from '../contexts/CurrentUser'

import {SketchPicker} from 'react-color';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const ExternalUsersTableRows = ({projectID}) => {
    const {data, loading, error} = useQuery(gql`
    query getExternalUsersOfBuilding ($projectID: String!) {
      building(where:{projectID:$projectID}) {
        id
        projectID
        externalBuildingRoles {
          id
          role
          user {
            id
            name
            email
            authId
          }
        }
      }
    } 
  `, {
        variables: {
            projectID,
        }
    })

    if (loading) return (
        <TableRow>
            <TableCell colSpan="4">loading...</TableCell>
        </TableRow>
    )

    if (error) return (
        <TableRow>
            <TableCell colSpan="4">ERROR...</TableCell>
        </TableRow>
    )

    if (data.building) return (
        <>
            {data.building.externalBuildingRoles && data.building.externalBuildingRoles
                .sort((a, b) => a.user.name.localeCompare(b.user.name))
                .map((link) => (
                    <TableRow key={link.user.id}>
                        <TableCell>{link.user.name}</TableCell>
                        <TableCell>{link.user.email}</TableCell>
                        <TableCell>EXTERNAL - {link.role}</TableCell>
                        <TableCell>{link.user.authId ? "Active [" + link.user.authId.split("|")[0] + "]" : "Created"}</TableCell>
                    </TableRow>
                ))}
        </>
    )

    return (
        <TableRow>
            <TableCell colSpan="4">No Data</TableCell>
        </TableRow>
    )
}

class SettingsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projectID: "",
            clientId: "",
            clientLogoId: "",
            clientLogoUrl: "",
            buildingImageId: "",
            buildingImageUrl: "",
            fontFamily: DefaultTheme.typography.fontFamily,
            primaryColor: DefaultTheme.palette.primary,
            secondaryColor: DefaultTheme.palette.secondary,
            customTheme: DefaultTheme,
            clientAdminNewClientName: "",
            buildingAdminSelectedClientId: "",
            buildingAdminSelectedBuildingIds: [],
            userAdminSelectedClientId: "",
            userAdminSelectedClient: null,
            userAdminNewUserName: "",
            userAdminNewUserEmail: "",
            userAdminNewUserRole: "READER",
            userAdminInviteSent: [],
            newTokenServiceName: "sqb-prisma-kbc",
            newTokenStageName: "prod",
            newTokenExpiresIn: "1d",
            newToken: "",
        }
    }

    componentDidMount() {
        if (!this.props.data.me) {
            return
        }

        if (!this.props.data.error && !this.props.data.loading) {
            this.setState({clientId: this.props.data.me.client.id});
            this.setState({buildingAdminSelectedClientId: this.props.data.me.client.id});
            if (this.props.data.me.client.buildings && this.props.data.me.client.buildings.length) {
                this.setState({buildingAdminSelectedBuildingIds: this.props.data.me.client.buildings.map(b => b.id)});
            }
            this.setState({userAdminSelectedClientId: this.props.data.me.client.id});
            if (this.props.data.me.client.logo && this.props.data.me.client.logo.file) {
                this.setState({clientLogoId: this.props.data.me.client.logo.id});
                this.setState({clientLogoUrl: this.props.data.me.client.logo.file.url});
            } else {
                this.setState({clientLogoId: ""});
                this.setState({clientLogoUrl: ""});
            }

            this.setState({fontFamily: this.props.data.me.client.themeFontFamily});
            this.setState({primaryColor: this.props.data.me.client.themePrimaryColor});
            this.setState({secondaryColor: this.props.data.me.client.themeSecondaryColor});
            this.updateStateTheme(
                this.props.data.me.client.themePrimaryColor,
                this.props.data.me.client.themeSecondaryColor,
                this.props.data.me.client.themeFontFamily,
            );
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.data.error && !nextProps.data.loading && nextProps.data.me) {
            if (this.state.buildingAdminSelectedClientId) {
                if (nextProps.data.clients && (nextProps.data.clients.findIndex(c => ((c.id === this.state.buildingAdminSelectedClientId) && c.buildings)) >= 0)) {
                    this.setState({buildingAdminSelectedBuildingIds: nextProps.data.clients.find(c => c.id === this.state.buildingAdminSelectedClientId).buildings.map(b => b.id)});
                } else {
                    this.setState({buildingAdminSelectedBuildingIds: []});
                }
            } else {
                this.setState({buildingAdminSelectedClientId: nextProps.data.me.client.id});
                if (nextProps.data.me.client.buildings && nextProps.data.me.client.buildings.length) {
                    this.setState({buildingAdminSelectedBuildingIds: nextProps.data.me.client.buildings.map(b => b.id)});
                }
            }
            if (!this.state.userAdminSelectedClientId) {
                this.setState({userAdminSelectedClientId: nextProps.data.me.client.id});
            }
            this.setState({clientId: nextProps.data.me.client.id});
            if (nextProps.data.me.client.logo && nextProps.data.me.client.logo.file) {
                this.setState({clientLogoId: nextProps.data.me.client.logo.id});
                this.setState({clientLogoUrl: nextProps.data.me.client.logo.file.url});
            } else {
                this.setState({clientLogoId: ""});
                this.setState({clientLogoUrl: ""});
            }

            this.setState({fontFamily: nextProps.data.me.client.themeFontFamily});
            this.setState({primaryColor: nextProps.data.me.client.themePrimaryColor});
            this.setState({secondaryColor: nextProps.data.me.client.themeSecondaryColor});
            this.updateStateTheme(
                nextProps.data.me.client.themePrimaryColor,
                nextProps.data.me.client.themeSecondaryColor,
                nextProps.data.me.client.themeFontFamily,
            );
        }
    }

    handleClientAdminNewClientNameChange = event => {
        this.setState({clientAdminNewClientName: event.target.value})
    }
    handleClientAdminNewClientDisplayNameChange = event => {
        this.setState({clientAdminNewClientDisplayName: event.target.value})
    }

    handleBuildingAdminClientIdChange = event => {
        this.setState({buildingAdminSelectedClientId: event.target.value})
        if (this.props.data && !this.props.data.error && !this.props.data.loading && this.props.data.clients) {
            if (this.props.data.clients.findIndex(c => ((c.id === event.target.value) && c.buildings)) >= 0) {
                this.setState({buildingAdminSelectedBuildingIds: this.props.data.clients.find(c => c.id === event.target.value).buildings.map(b => b.id)});
            } else {
                this.setState({buildingAdminSelectedBuildingIds: []});
            }
        }
    }
    handleBuildingAdminSelectedBuildingIdsChange = event => {
        this.setState({buildingAdminSelectedBuildingIds: event.target.value})
    }

    handleUserAdminClientChange = event => {
        this.setState({userAdminSelectedClientId: event.target.value})
    }
    handleUserAdminNewUserNameChange = event => {
        this.setState({userAdminNewUserName: event.target.value})
    }
    handleUserAdminNewUserEmailChange = event => {
        this.setState({userAdminNewUserEmail: event.target.value})
    }
    handleUserAdminNewUserRoleChange = event => {
        this.setState({userAdminNewUserRole: event.target.value})
    }

    handleClientAdd = event => {
        this.props.createClientMutation({
            variables: {
                name: this.state.clientAdminNewClientName,
                displayName: this.state.clientAdminNewClientDisplayName,
            },
            refetchQueries: [{
                query: updateClientsCache
            }],
        }).then(({data}) => {
            console.log('got data', data);
        }).catch((error) => {
            console.log('there was an error sending the query', error);
        });
        this.setState({clientAdminNewClientName: ""});
        this.setState({clientAdminNewClientDisplayName: ""});
    }

    handleClientBuildingsUpdate = event => {
        this.props.updateClientBuildingsMutation({
            variables: {
                clientId: this.state.buildingAdminSelectedClientId,
                buildings: this.state.buildingAdminSelectedBuildingIds.map(e => ({id: e})),
            },
            refetchQueries: [{
                query: updateClientsCache
            }],
        }).then(({data}) => {
            console.log('got data', data);
        }).catch((error) => {
            console.log('there was an error sending the query', error);
        });
    }

    handleUserAdd = event => {
        this.props.createAuth0UserMutation({
            variables: {
                clientId: this.state.userAdminSelectedClientId,
                name: this.state.userAdminNewUserName,
                email: this.state.userAdminNewUserEmail,
                role: this.state.userAdminNewUserRole,
            },
            refetchQueries: [{
                query: updateClientsCache
            }],
        }).then(({data}) => {
            console.log('got data', data);
        }).catch((error) => {
            console.log('there was an error sending the query', error);
        });
        this.setState({userAdminNewUserName: ""});
        this.setState({userAdminNewUserEmail: ""});
        this.setState({userAdminNewUserRole: "READER"});
    }
    handleSendInvite = (userId) => {
        console.log(`send invite mail to user ${userId}`)
        this.props.sendAuth0PasswordResetMutation({
            variables: {userId},
            refetchQueries: [{
                query: updateClientsCache
            }],
        }).then(({data}) => {
            console.log(data)
            console.log(this.state.userAdminInviteSent)
            console.log(this.state.userAdminInviteSent.concat(data.sendAuth0PasswordReset.id))
            this.setState({userAdminInviteSent: this.state.userAdminInviteSent.concat(data.sendAuth0PasswordReset.id)})
            console.log('got data', data);
        }).catch((error) => {
            console.log('there was an error sending the query', error);
        });
    }
    handleUserDelete = (userId) => {
        this.props.deleteAuth0UserMutation({
            variables: {userId},
            refetchQueries: [{
                query: updateClientsCache
            }],
        }).then(({data}) => {
            console.log('got data', data);
        }).catch((error) => {
            console.log('there was an error sending the query', error);
        });
    }

    handleNewTokenServiceNameChange = event => {
        this.setState({newTokenServiceName: event.target.value})
    }
    handleNewTokenStageNameChange = event => {
        this.setState({newTokenStageName: event.target.value})
    }
    handleNewTokenExpiresInChange = event => {
        this.setState({newTokenExpiresIn: event.target.value})
    }
    handleNewToken = () => {
        this.props.getBackendTokenMutation({
            variables: {
                serviceName: this.state.newTokenServiceName,
                stageName: this.state.newTokenStageName,
                expiresIn: this.state.newTokenExpiresIn,
            },
        }).then(({data}) => {
            console.log('got data', data);
            this.setState({newToken: data.getBackendToken});
        }).catch((error) => {
            console.log('there was an error sending the query', error);
        });
    }

    handleBuildingImageAdd = (uploadedFileId, uploadedFileName) => {
        this.props.createBuildingImageMutation({
            variables: {
                projectID: this.state.projectID,
                fileId: uploadedFileId,
            },
            refetchQueries: [{query: updateUserCache}],
        }).then(({data}) => {
            console.log('got data', data);
        }).catch((error) => {
            console.log('there was an error sending the query', error);
        });
    }

    handleBuildingImageDelete = (buildingImageId) => {
        this.props.deleteBuildingImageMutation({
            variables: {
                buildingImageId: this.state.buildingImageId,
            },
            refetchQueries: [{query: updateUserCache}],
        }).then(({data}) => {
            console.log('got data', data);
        }).catch((error) => {
            console.log('there was an error sending the query', error);
        });
    }

    handleClientLogoAdd = (uploadedFileId, uploadedFileName) => {
        this.props.createClientLogoMutation({
            variables: {
                clientId: this.state.clientId,
                fileId: uploadedFileId,
            },
            refetchQueries: [{query: updateUserCache}],
        }).then(({data}) => {
            console.log('got data', data);
        }).catch((error) => {
            console.log('there was an error sending the query', error);
        });
    }

    handleClientLogoDelete = (clientLogoId) => {
        this.props.deleteClientLogoMutation({
            variables: {
                clientLogoId: this.state.clientLogoId,
            },
            refetchQueries: [{query: updateUserCache}],
        }).then(({data}) => {
            console.log('got data', data);
        }).catch((error) => {
            console.log('there was an error sending the query', error);
        });
    }

    updateStateTheme = (primaryColor, secondaryColor, fontFamily) => {
        const palette = createPalette({
            type: 'light',
            primary: {main: primaryColor},
            secondary: {main: secondaryColor},
        });
        this.setState({
            customTheme: createMuiTheme({
                palette: palette,
                typography: createTypography(palette, {fontFamily: fontFamily}),
            })
        });
    }

    handleFontFamilyChange = (event) => {
        this.setState({fontFamily: event.target.value});
        this.updateStateTheme(this.state.primaryColor, this.state.secondaryColor, event.target.value);
    };

    handlePrimaryColorChangeComplete = (color) => {
        this.setState({primaryColor: color.hex});
        this.updateStateTheme(color.hex, this.state.secondaryColor, this.state.fontFamily);
    };

    handleSecondaryColorChangeComplete = (color) => {
        this.setState({secondaryColor: color.hex});
        this.updateStateTheme(this.state.primaryColor, color.hex, this.state.fontFamily);
    };

    handleThemeSave = () => {
        this.props.updateClientMutation({
            variables: {
                clientId: this.state.clientId,
                primaryColor: this.state.primaryColor,
                secondaryColor: this.state.secondaryColor,
                fontFamily: this.state.fontFamily,
            },
            refetchQueries: [{query: updateUserCache}],
        }).then(({data}) => {
            console.log('got data', data);
        }).catch((error) => {
            console.log('there was an error sending the query', error);
        });
    }

    render() {
        return (
            <CurrentUserContext.Consumer>{me => {
                return (
                    <div>
                        <div>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography>Current User</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={4}>
                                        {this.props.data.error ? (
                                            <Grid item xs={12}>
                                                <Typography gutterBottom>Error fetching user data!</Typography>
                                            </Grid>
                                        ) : this.props.data.loading ? (
                                            <Grid item xs={12}>
                                                <CircularProgress size={50} color="secondary"/>
                                            </Grid>
                                        ) : !this.props.data.me ? (
                                            <Grid item xs={12}>
                                                <Typography>Loading user Data...</Typography>
                                            </Grid>
                                        ) : (
                                            <Grid item xs={12}>
                                                <Typography>{this.props.data.me.name} [client: {this.props.data.me.client.displayName} -
                                                    role: {me.role} - email:{this.props.data.me.email}]</Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion hidden={true}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography>Building</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={4}>
                                        {this.props.data.error ? (
                                            <Grid item xs={12}>
                                                <Typography gutterBottom>Error fetching building data!</Typography>
                                            </Grid>
                                        ) : this.props.data.loading ? (
                                            <Grid item xs={12}>
                                                <CircularProgress size={50} color="secondary"/>
                                            </Grid>
                                        ) : (
                                            this.state.buildingImageUrl ? (
                                                <div>
                                                    <img alt="Building Illustration" src={this.state.buildingImageUrl}
                                                         style={{
                                                             backgroundColor: "#999999",
                                                             maxWidth: "500px",
                                                             maxHeight: "500px"
                                                         }}/>
                                                    <br/><br/>
                                                    <Button onClick={this.handleBuildingImageDelete}>
                                                        Delete Image&nbsp;&nbsp;
                                                        <DeleteIcon/>
                                                    </Button>
                                                </div>
                                            ) : (
                                                <Grid item>
                                                    <Typography>
                                                        Upload a building image for {this.state.projectID}
                                                    </Typography>
                                                    <Typography>
                                                        &nbsp;
                                                    </Typography>
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            {(this.props.data.error || this.props.data.loading || !this.props.data.me) ? (
                                <Accordion hidden={true}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography>Client Users</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>Client Users</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ) : (
                                <Accordion
                                    hidden={!(me.role === "OPERATOR" || me.role === "ADMIN" || me.role === "SUPERADMIN")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography>Client Users</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Name</TableCell>
                                                            <TableCell>Email</TableCell>
                                                            <TableCell>Role</TableCell>
                                                            <TableCell>Status</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.props.data.me.client.users
                                                            .sort((a, b) => a.name.localeCompare(b.name))
                                                            .map(user => (
                                                                <TableRow key={user.id}>
                                                                    <TableCell>{user.name}</TableCell>
                                                                    <TableCell>{user.email}</TableCell>
                                                                    <TableCell>{user.role}</TableCell>
                                                                    <TableCell>{user.authId ? "Active [" + user.authId.split("|")[0] + "]" : "Created"}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        <ExternalUsersTableRows projectID={this.state.projectID}/>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            )}
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Typography>Client Dashboard</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {this.props.data.error ? (
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography gutterBottom>Error fetching dashboard data!</Typography>
                                            </Grid>
                                        </Grid>
                                    ) : this.props.data.loading ? (
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <CircularProgress size={50} color="secondary"/>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid container spacing={4}>
                                            <Grid item>
                                                {this.state.clientLogoUrl ? (
                                                    <div>
                                                        <img alt="Company Logo" src={this.state.clientLogoUrl} style={{
                                                            backgroundColor: "#999999",
                                                            maxWidth: "300px",
                                                            maxHeight: "300px"
                                                        }}/>
                                                        <br/><br/>
                                                        <Button variant="contained"
                                                                onClick={this.handleClientLogoDelete}>
                                                            Delete Logo&nbsp;&nbsp;
                                                            <DeleteIcon/>
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <Typography color="primary">
                                                            Upload a logo for {this.state.clientId}
                                                        </Typography>
                                                        <Typography>
                                                            &nbsp;
                                                        </Typography>
                                                        <UploadClientLogo clientId={this.state.clientId}/>
                                                    </div>
                                                )}
                                            </Grid>
                                            <Grid item>
                                                <div style={{
                                                    fontFamily: this.state.fontFamily,
                                                    backgroundColor: this.state.primaryColor,
                                                    padding: "5px"
                                                }}>
                                                    <Typography>
                                                        Primary color
                                                    </Typography>
                                                    <Typography>
                                                        &nbsp;
                                                    </Typography>
                                                    <SketchPicker
                                                        color={this.state.primaryColor}
                                                        onChangeComplete={this.handlePrimaryColorChangeComplete}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <div style={{
                                                    fontFamily: this.state.fontFamily,
                                                    backgroundColor: this.state.secondaryColor,
                                                    padding: "5px"
                                                }}>
                                                    <Typography>
                                                        Secondary color
                                                    </Typography>
                                                    <Typography>
                                                        &nbsp;
                                                    </Typography>
                                                    <SketchPicker
                                                        color={this.state.secondaryColor}
                                                        onChangeComplete={this.handleSecondaryColorChangeComplete}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <InputLabel htmlFor="font" color="primary">Font</InputLabel><br/>
                                                <Select
                                                    value={this.state.fontFamily}
                                                    onChange={this.handleFontFamilyChange}
                                                    input={<Input name="fontFamily" id="fontFamily"/>}
                                                >
                                                    <MenuItem value='montserrat'>Montserrat</MenuItem>
                                                    <MenuItem value='roboto'>Roboto</MenuItem>
                                                    <MenuItem value='lato'>Lato</MenuItem>
                                                    <MenuItem value='verdana'>Verdana</MenuItem>
                                                </Select>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <MuiThemeProvider theme={this.state.customTheme}>
                                                    <div>
                                                        <Typography variant="h6">Example text</Typography><br/>
                                                        <Typography color="primary">Primary color text</Typography><br/>
                                                        <Typography color="secondary">Secondary color
                                                            text</Typography><br/>
                                                    </div>
                                                </MuiThemeProvider>
                                                <br/>
                                                <Button variant="contained" onClick={this.handleThemeSave}>
                                                    Save Theme&nbsp;&nbsp;
                                                    <SaveIcon/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div><br/></div>
                        {!(this.props.data.error || this.props.data.loading || !me) && (
                            <div>
                                <Accordion hidden={!(me.role === "SUPERADMIN")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography>Client Administration</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={4}>
                                            {!(this.props.data.error || this.props.data.loading)
                                                && this.props.data.clients && (
                                                    <Grid item xs={12}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Name (short &amp; lowercase - prefix for
                                                                        ProjectID)</TableCell>
                                                                    <TableCell>Display Name</TableCell>
                                                                    <TableCell>&nbsp;</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow key="add">
                                                                    <TableCell>
                                                                        <TextField
                                                                            id="select-newClientName"
                                                                            value={this.state.clientAdminNewClientName}
                                                                            margin="normal"
                                                                            onChange={this.handleClientAdminNewClientNameChange}
                                                                        >
                                                                        </TextField>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            id="select-newClientDisplayName"
                                                                            value={this.state.clientAdminNewClientDisplayName}
                                                                            margin="normal"
                                                                            onChange={this.handleClientAdminNewClientDisplayNameChange}
                                                                        >
                                                                        </TextField>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Button variant="contained"
                                                                                style={{marginLeft: 10}}
                                                                                aria-label="AddClient"
                                                                                onClick={this.handleClientAdd}>
                                                                            Create New Client
                                                                            <SaveIcon style={{marginLeft: 10}}/>
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </Grid>
                                                )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion hidden={!(me.role === "SUPERADMIN")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography>Building Administration</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={4}>
                                            {!(this.props.data.error || this.props.data.loading)
                                                && this.props.data.clients && this.state.buildingAdminSelectedBuildingIds && (
                                                    <Grid item xs={12}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Client</TableCell>
                                                                    <TableCell>Buildings</TableCell>
                                                                    <TableCell></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <TextField
                                                                            id="select-buildingAdminClient"
                                                                            select
                                                                            value={this.state.buildingAdminSelectedClientId}
                                                                            onChange={this.handleBuildingAdminClientIdChange}
                                                                            margin="normal"
                                                                            style={{width: 250}}
                                                                        >
                                                                            {this.props.data.clients.sort((a, b) => a.displayName.localeCompare(b.displayName)).map(option => (
                                                                                <MenuItem key={option.id}
                                                                                          value={option.id}>
                                                                                    {option.displayName + ((option.buildings && option.buildings.length) ? ' (' + option.buildings.length + ')' : ' (-none-)')}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </TextField>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Select
                                                                            multiple
                                                                            value={this.state.buildingAdminSelectedBuildingIds}
                                                                            onChange={this.handleBuildingAdminSelectedBuildingIdsChange}
                                                                            input={<Input
                                                                                id="select-buildingAdminClientBuildings"/>}
                                                                            renderValue={selected => selected.length > 5
                                                                                ? '< ' + selected.length + ' buildings selected >'
                                                                                : this.props.data.buildings.filter(building => selected.indexOf(building.id) > -1).sort((a, b) => a.displayName.localeCompare(b.displayName)).map(building => building.displayName).join(', ')}
                                                                            MenuProps={MenuProps}
                                                                            style={{width: 600}}
                                                                        >
                                                                            {this.props.data.buildings.sort((a, b) => a.displayName.localeCompare(b.displayName)).map(building => (
                                                                                <MenuItem key={building.id}
                                                                                          value={building.id}>
                                                                                    <Checkbox
                                                                                        checked={this.state.buildingAdminSelectedBuildingIds.indexOf(building.id) > -1}/>
                                                                                    <ListItemText
                                                                                        primary={building.displayName}/>
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Button variant="contained"
                                                                                style={{marginLeft: 10}}
                                                                                aria-label="UpdateClientBuildings"
                                                                                onClick={this.handleClientBuildingsUpdate}>
                                                                            Update Client Buildings
                                                                            <SaveIcon style={{marginLeft: 10}}/>
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </Grid>
                                                )}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion hidden={!(me.role === "SUPERADMIN")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography>User Administration</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {!(this.props.data.error || this.props.data.loading)
                                            && this.props.data.clients && this.state.userAdminSelectedClientId && (
                                                <Grid container spacing={4}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            id="select-userAdminClient"
                                                            select
                                                            value={this.state.userAdminSelectedClientId}
                                                            onChange={this.handleUserAdminClientChange}
                                                            margin="normal"
                                                            style={{width: 250}}
                                                        >
                                                            {this.props.data.clients.sort((a, b) => a.displayName.localeCompare(b.displayName)).map(option => (
                                                                <MenuItem key={option.id} value={option.id}>
                                                                    {option.displayName}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Name</TableCell>
                                                                    <TableCell>Email</TableCell>
                                                                    <TableCell>Role</TableCell>
                                                                    <TableCell>Status</TableCell>
                                                                    <TableCell></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {this.props.data.clients.filter(c => c.id === this.state.userAdminSelectedClientId)[0]
                                                                    .users.sort((a, b) => a.name.localeCompare(b.name))
                                                                    .map(user => (
                                                                        <TableRow key={user.id}>
                                                                            <TableCell>{user.name}</TableCell>
                                                                            <TableCell>{user.email}</TableCell>
                                                                            <TableCell>{user.role}</TableCell>
                                                                            <TableCell>{user.authId ? "Active [" + user.authId.split("|")[0] + "]" : "Created"}</TableCell>
                                                                            <TableCell>
                                                                                <Button variant="contained"
                                                                                        style={{marginLeft: 10}}
                                                                                        aria-label="SendInviteMail"
                                                                                        onClick={() => this.handleSendInvite(user.id)}>
                                                                                    Send Invite {
                                                                                    this.state.userAdminInviteSent.includes(user.id)
                                                                                        ? <DoneIcon
                                                                                            style={{marginLeft: 10}}/>
                                                                                        : <MailIcon
                                                                                            style={{marginLeft: 10}}/>
                                                                                }
                                                                                </Button>
                                                                                <Button variant="contained"
                                                                                        style={{marginLeft: 10}}
                                                                                        aria-label="DeleteUser"
                                                                                        onClick={() => this.handleUserDelete(user.id)}>
                                                                                    Delete
                                                                                    <DeleteIcon
                                                                                        style={{marginLeft: 10}}/>
                                                                                </Button>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                <TableRow key="add">
                                                                    <TableCell>
                                                                        <TextField
                                                                            id="select-newUserName"
                                                                            value={this.state.userAdminNewUserName}
                                                                            margin="normal"
                                                                            onChange={this.handleUserAdminNewUserNameChange}
                                                                        >
                                                                        </TextField>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            id="select-newUserEmail"
                                                                            value={this.state.userAdminNewUserEmail}
                                                                            margin="normal"
                                                                            onChange={this.handleUserAdminNewUserEmailChange}
                                                                        >
                                                                        </TextField>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <TextField
                                                                            id="select-newUserRole"
                                                                            select
                                                                            value={this.state.userAdminNewUserRole}
                                                                            margin="normal"
                                                                            onChange={this.handleUserAdminNewUserRoleChange}
                                                                        >
                                                                            <MenuItem key="READER" value="READER">Read-only
                                                                                User</MenuItem>
                                                                            <MenuItem key="OPERATOR"
                                                                                      value="OPERATOR">Operator</MenuItem>
                                                                            <MenuItem key="ADMIN"
                                                                                      value="ADMIN">Admin</MenuItem>
                                                                            <MenuItem key="SUPERADMIN"
                                                                                      value="SUPERADMIN"
                                                                                      hidden={!(me.role === "SUPERADMIN")}>Super
                                                                                Admin</MenuItem>
                                                                        </TextField>
                                                                    </TableCell>
                                                                    <TableCell></TableCell>
                                                                    <TableCell>
                                                                        <Button variant="contained"
                                                                                style={{marginLeft: 10}}
                                                                                aria-label="AddUser"
                                                                                onClick={this.handleUserAdd}>
                                                                            Create New User
                                                                            <SaveIcon style={{marginLeft: 10}}/>
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </Grid>
                                                </Grid>
                                            )}
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion hidden={!(me.role === "SUPERADMIN")}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography>Backend API Token</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>
                                                                <TextField
                                                                    id="select-newTokenServiceName"
                                                                    select
                                                                    value={this.state.newTokenServiceName}
                                                                    margin="normal"
                                                                    onChange={this.handleNewTokenServiceNameChange}
                                                                >
                                                                    <MenuItem key="sqb-prisma"
                                                                              value="sqb-prisma">sqb-prisma</MenuItem>
                                                                    <MenuItem key="sqb-prisma-kbc"
                                                                              value="sqb-prisma-kbc">sqb-prisma-kbc</MenuItem>
                                                                </TextField>
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    id="select-newTokenStageName"
                                                                    select
                                                                    value={this.state.newTokenStageName}
                                                                    margin="normal"
                                                                    onChange={this.handleNewTokenStageNameChange}
                                                                >
                                                                    <MenuItem key="dev" value="dev">dev</MenuItem>
                                                                    <MenuItem key="prod" value="prod">prod</MenuItem>
                                                                </TextField>
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    id="select-newTokenExpiresIn"
                                                                    value={this.state.newTokenExpiresIn}
                                                                    margin="normal"
                                                                    onChange={this.handleNewTokenExpiresInChange}
                                                                >
                                                                </TextField>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button variant="contained" style={{marginLeft: 10}}
                                                                        aria-label="GetToken"
                                                                        onClick={this.handleNewToken}>
                                                                    Get token
                                                                    <VpnKeyIcon style={{marginLeft: 10}}/>
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={4}>
                                                                <TextField
                                                                    id="select-newToken"
                                                                    value={this.state.newToken}
                                                                    margin="normal"
                                                                    fullWidth={true}
                                                                    multiline={true}
                                                                    rows={2}
                                                                    rowsMax={5}
                                                                >
                                                                </TextField>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )}
                    </div>
                )
            }}
            </CurrentUserContext.Consumer>
        )
    }
}

const createAuth0User = gql`
mutation createAuth0User($clientId: ID!, $name: String!, $email: String!, $role: String!) {
  createAuth0User(data: {client: {connect: {id: $clientId}}, name: $name, email: $email, role: $role}) {
    id
  }
}`;

const sendAuth0PasswordReset = gql`
mutation sendAuth0PasswordReset($userId: ID!) {
  sendAuth0PasswordReset(where: {id: $userId}) {
    id
  }
}`;

const deleteAuth0User = gql`
mutation deleteAuth0User($userId: ID!) {
  deleteAuth0User(where: {id: $userId}) {
    id
  }
}`;

const getBackendToken = gql`
mutation getBackendToken($serviceName: String!, $stageName: String!, $expiresIn: String!) {
  getBackendToken(serviceName:$serviceName, stageName:$stageName, expiresIn:$expiresIn)
}`;

const createBuildingImage = gql`
mutation createBuildingImage($projectID: String!, $fileId: ID!) {
  createBuildingImage(data: {projectID: $projectID, fileId: $fileId}) {
    id
  }
}`;

const deleteBuildingImage = gql`
mutation deleteBuildingImage($buildingImageId: ID!) {
  deleteBuildingImage(where: {id:$buildingImageId}) {
    id
  }
}`;

const createClient = gql`
mutation createClient($name: String!, $displayName: String!) {
  createClient(
    name: $name
    displayName: $displayName
  ) { id }
}`;

const uploadClientLogo = gql`
mutation uploadClientLogo($clientId: ID!, $file: Upload!) {
  uploadClientLogo(clientId: $clientId, file: $file) {
    id
  }
}`;

const createClientLogo = gql`
mutation createClientLogo($clientId: ID!, $fileId: ID!) {
  createClientLogo(data: {clientId:$clientId, fileId: $fileId}) {
    id
  }
}`;

const deleteClientLogo = gql`
mutation deleteClientLogo($clientLogoId: ID!) {
  deleteClientLogo(id:$clientLogoId) {
    id
  }
}`;

const updateClientBuildings = gql`
mutation updateClientBuildings($clientId: ID!, $buildings: [BuildingWhereUniqueInput!]) {
  updateClientBuildings(
    id: $clientId
    buildings: $buildings
  ) { id }
}`;

const updateClient = gql`
mutation updateClient($clientId: ID!, $primaryColor: String, $secondaryColor: String, $fontFamily: String) {
  updateClient(
    id: $clientId
    themePrimaryColor: $primaryColor
    themeSecondaryColor: $secondaryColor
    themeFontFamily: $fontFamily
  ) {
    id
  }
}`;

const updateClientsCache = gql`
query updateClientsCache {
  clients {
    id
    name
    displayName
    users {
      id
      name
      email
      role
      authId
    }
    buildings {
      id
      displayName
      projectID
    }
  }
}
`

const updateUserCache = gql`
query updateUserCache {
  me {
    id
    email
    name
    client {
      id
      displayName
      themeFontFamily
      themePrimaryColor
      themeSecondaryColor
      logo {
        id
        file {
          id
          url
        }
      }
      buildings {
        id
        projectID
        displayName
        description
        controlStatus
        controlStatusSetpoint
        controlEventHistory {
          id
          payload
        }
        comfortSettingThermal
        comfortSettingAirquality
        lastDataPushDateTime
        location {
          id
          city
        }
        image {
          id
          file {
            id
            url
          }
        }
      }
      users {
        id
        name
        email
        role
        authId
      }
    }
  }
}`;

const getAllUsersAndMe = gql`
query getAllUsersAndMe {
  clients {
    id
    displayName
    users {
      id
      name
      email
      role
      authId
    }
    buildings {
      id
      displayName
      projectID
    }
  }
  buildings {
    id
    projectID
    displayName
  }
  me {
    id
    email
    name
    role
    client {
      id
      displayName
      themeFontFamily
      themePrimaryColor
      themeSecondaryColor
      logo {
        id
        file {
          id
          url
        }
      }
      buildings {
        id
        projectID
        displayName
        description
        controlStatus
        controlStatusSetpoint
        controlEventHistory {
          id
          payload
        }
        comfortSettingThermal
        comfortSettingAirquality
        lastDataPushDateTime
        location {
          id
          city
        }
        image {
          id
          file {
            id
            url
          }
        }
      }
      users {
        id
        name
        email
        role
        authId
      }
    }
  }
}`

export default compose(
    graphql(getAllUsersAndMe),
    graphql(createClient, {name: 'createClientMutation'}),
    graphql(updateClientBuildings, {name: 'updateClientBuildingsMutation'}),
    graphql(createAuth0User, {name: 'createAuth0UserMutation'}),
    graphql(sendAuth0PasswordReset, {name: 'sendAuth0PasswordResetMutation'}),
    graphql(deleteAuth0User, {name: 'deleteAuth0UserMutation'}),
    graphql(getBackendToken, {name: 'getBackendTokenMutation'}),
    graphql(createBuildingImage, {name: 'createBuildingImageMutation'}),
    graphql(deleteBuildingImage, {name: 'deleteBuildingImageMutation'}),
    graphql(uploadClientLogo, {name: 'uploadClientLogoMutation'}),
    graphql(createClientLogo, {name: 'createClientLogoMutation'}),
    graphql(deleteClientLogo, {name: 'deleteClientLogoMutation'}),
    graphql(updateClient, {name: 'updateClientMutation'}),
)(SettingsPage);
