import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag'

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';

import ConfigSystemMetricEdit from './ConfigSystemMetricEdit'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ConfigSystem = props => {
  const { projectID, systemId, parentRefetch } = props

  const [ displayName, setDisplayName ] = useState("")
  const [ displayNameEditState, setDisplayNameEditState ] = useState(false)
  const [ selectedSpaceIds, setSpaceIds ] = useState([])
  const [ selectedSystemMetricTemplateId, setSystemMetricTemplateId ] = useState("---")

  const { data, loading, error, refetch } = useQuery(getSystemData, {
    variables: { systemId, projectID },
    onCompleted: data => {
        data.system.displayName && (data.system.displayName.length > 0) && setDisplayName(data.system.displayName);
        !(data.system.displayName && (data.system.displayName.length > 0)) && setDisplayNameEditState(true);
        data.system.spaces && setSpaceIds(data.system.spaces.map(space=>space.id));
    }
  })

  const [ deleteSystemMutation ] = useMutation(deleteSystem)
  const [ updateDisplayNameMutation ] = useMutation(updateDisplayName)
  const [ updateSystemSpacesMutation ] = useMutation(updateSystemSpaces)
  const [ createSystemMetricOnSystemMutation ] = useMutation(createSystemMetricOnSystem)

  if (loading) return (<Grid><CircularProgress size={30} color="secondary" /></Grid>)
  if (error) return (<Grid><Typography gutterBottom>Error fetching System data!</Typography></Grid>)
  if (!data.system) return (<Typography gutterBottom>No System Found!</Typography>)

  const system = data.system
  const buildingSpaces = data.spaces
  const systemMetricTags = data.systemMetricTags

  const systemMetricTagCreatorConfigwizardId = systemMetricTags.filter(t => (t.key==="creator" && t.value==="configwizard")).map(t => (t.id))[0]

  const enableDisplayNameEdit = async () => { await setDisplayNameEditState(true) }
  const disableDisplayNameEdit = async () => { await setDisplayNameEditState(false) }

  const handleDisplayNameUpdate = async () => {
    await updateDisplayNameMutation({ variables: { systemId, displayName }})
    await disableDisplayNameEdit()
    await refetch()
  }

  const handleSelectedSpaceIdsChange = async (event) => {
    await setSpaceIds(event.target.value);
    await updateSystemSpacesMutation({
      variables: {
        systemId: systemId,
        spaces: event.target.value.map(id => ({"id": id})),
    }});
    await refetch()
  };

  const handleSystemDelete = async () => {
    await deleteSystemMutation({ variables: { systemId }})
    await parentRefetch()
  }

  const handleSystemMetricAdd = async () => {
    if (selectedSystemMetricTemplateId !== "---") {
      await createSystemMetricOnSystemMutation({
        variables: {
          projectID: projectID,
          systemId: systemId,
          systemMetricTemplateId: selectedSystemMetricTemplateId,
          payload:{},
          userInput:{},
          aggregation:"",
          systemMetricTags:[
            {"id": systemMetricTagCreatorConfigwizardId},
            {"id": system.systemTemplate.systemMetricTemplates.find(smt => (smt.id === selectedSystemMetricTemplateId)).possibleFunctionTags[0].id}
          ],
          rawTrajectories:[],
          profileContextCreateOneWithoutSystemMetricConversionsInput: null,
      }})
      await refetch()
    }
  }

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" >{displayName}</Typography>
        <Typography variant="h6" style={{flex:1}}>&nbsp;</Typography>
        <Hidden xlDown={displayNameEditState}>
          <Icon onClick={enableDisplayNameEdit}>edit</Icon>
        </Hidden>
        <Hidden xlDown={!displayNameEditState}>
          <Input
            id="displayName-input"
            placeholder={system.systemTemplate.displayName + " Name"}
            value={displayName}
            onChange={(event) => {setDisplayName(event.target.value)}}
          />
          &nbsp;&nbsp;&nbsp;
          <Button size="small" variant="contained" color="primary" onClick={handleDisplayNameUpdate}>
            Update&nbsp;&nbsp;&nbsp;<Icon>save</Icon>
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button size="small" variant="contained" color="primary" onClick={disableDisplayNameEdit}>
            Cancel&nbsp;&nbsp;&nbsp;<Icon>cancel</Icon>
          </Button>
        </Hidden>
        <Typography variant="h6">&nbsp;</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" gutterBottom>Related Space(s)</Typography>
              </TableCell>
              <TableCell>
                <Select
                  multiple
                  value={selectedSpaceIds}
                  onChange={handleSelectedSpaceIdsChange}
                  input={<Input id="spaces-multiple" />}
                  renderValue={selected => buildingSpaces.filter(space => selected.indexOf(space.id)>-1).map(space=>space.displayName).join(', ')}
                  MenuProps={MenuProps}
                >
                  {buildingSpaces.map(space => (
                    <MenuItem key={space.id} value={space.id}>
                      <Checkbox checked={selectedSpaceIds.indexOf(space.id)>-1} />
                      <ListItemText primary={space.displayName} />
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" gutterBottom>Configure New Metric</Typography>
              </TableCell>
              <TableCell>
                <TextField
                  id="select-system-metric-template"
                  select
                  value={selectedSystemMetricTemplateId}
                  onChange={(event) => {setSystemMetricTemplateId(event.target.value)}}
                  margin="normal"
                >
                  <MenuItem key={"---"} value={"---"}>
                    -- Select Metric to Add --
                  </MenuItem>
                  {system.systemTemplate.systemMetricTemplates
                    .filter(smt => (smt.possibleFunctionTags && (smt.possibleFunctionTags.length > 0)))
                    .sort((a, b) => (a.guiOrder - b.guiOrder || (a.displayName > b.displayName ? 1 : -1)))
                    .map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.displayName}
                      </MenuItem>
                  ))}
                </TextField>
              </TableCell>
              <TableCell align="right">
                <Button size="small" variant="contained" color="primary" onClick={handleSystemMetricAdd}>
                  <Icon>add</Icon>&nbsp;&nbsp;&nbsp;Add&nbsp;
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </AccordionDetails>
      <Divider />
      <AccordionDetails>
        <Table>
          <TableBody>
            {Array.from(system.systemMetrics)
              .sort((a, b) => (a.systemMetricTemplate.guiOrder - b.systemMetricTemplate.guiOrder || (a.systemMetricTemplate.displayName > b.systemMetricTemplate.displayName ? 1 : -1)))
              .map((systemMetric, index) => {
              return ((systemMetric.systemMetricTags.findIndex(t => t.key === "function") > -1)
              ? (
                <ConfigSystemMetricEdit
                  key={systemMetric.id}
                  projectID={projectID}
                  systemMetricId={systemMetric.id}
                  parentRefetch={refetch} />
              ) : ( null ))
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <Button size="small" variant="contained" color="primary" onClick={handleSystemDelete} >
          <Icon>delete</Icon>&nbsp;&nbsp;&nbsp;Delete&nbsp;{system.systemTemplate.displayName}&nbsp;"{system.displayName}"&nbsp;
        </Button>
      </AccordionActions>
    </Accordion>
  );
}

const deleteSystem = gql`
mutation deleteSystem($systemId: ID!) {
  deleteSystem(id: $systemId) { id }
}`;

const updateDisplayName = gql`
mutation updateDisplayName($systemId: ID!, $displayName: String!) {
  updateSystemDisplayName(
    id: $systemId
    displayName: $displayName
  ) { id displayName }
}`;

const updateSystemSpaces = gql`
mutation updateSystemSpaces($systemId: ID!, $spaces: [SpaceWhereUniqueInput!]) {
  updateSystemSpaces (
    id: $systemId
    spaces: $spaces
  ) {
    id
    displayName
    spaces { id displayName }
  }
}`;

const createSystemMetricOnSystem = gql`
mutation createSystemMetricOnSystem($projectID: String!, $systemId: ID!, $systemMetricTemplateId: ID!, $payload: Json!, $userInput:Json!, $aggregation:String!, $systemMetricTags: [SystemMetricTagWhereUniqueInput!], $rawTrajectories: [RawTrajectoryWhereUniqueInput!], $profileContextCreateOneWithoutSystemMetricConversionsInput: ProfileContextCreateOneWithoutSystemMetricConversionsInput) {
  createSystemMetricOnSystem(
    projectID: $projectID
    systemId: $systemId
    systemMetricTemplateId: $systemMetricTemplateId
    payload: $payload
    userInput: $userInput
    aggregation: $aggregation
    systemMetricTags: $systemMetricTags
    rawTrajectories: $rawTrajectories
    profileContextCreateOneWithoutSystemMetricConversionsInput: $profileContextCreateOneWithoutSystemMetricConversionsInput
    ) { id }
}`;

const getSystemData = gql`
query getSystemData($systemId: ID!, $projectID: String!) {
  systemMetricTags {id key value}
  spaces (projectID: $projectID) {
    id
    displayName
    slug
  }
  system (id: $systemId) {
    id
    displayName
    slug
    systemTemplate {
      id
      name
      displayName
      systemMetricTemplates (where: {level:0}) {
        id
        name
        displayName
        guiOrder
        possibleFunctionTags {id key value}
      }
    }
    spaces {
      id
      displayName
      slug
    }
    systemMetrics (where: {systemMetricTemplate: {level:0} systemMetricTags_some:{key:"creator", value:"configwizard"}}) {
      id
      userInput
      payload
      systemMetricTags {id key value}
      systemMetricTemplate {
        id
        name
        displayName
        guiOrder
        possibleFunctionTags {id key value}
      }
    }
  }
}`;

export default ConfigSystem
