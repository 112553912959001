import React from 'react';
import {withRouter} from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {graphql} from 'react-apollo';
import {flowRight as compose} from 'lodash';
import gql from 'graphql-tag';

import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import TextField from '@material-ui/core/TextField';

import moment, {now} from 'moment'
import {TimeRange, TimeRangeEvent, TimeSeries} from "pondjs";
import {ChartContainer, ChartRow, Charts, EventChart, Resizable} from "react-timeseries-charts";
import {CurrentUserContext} from '../contexts/CurrentUser';
import {computeStatusColor} from "./ComputeStatusColor";

const PresentationIcon = props => (<SvgIcon {...props}>
    <path
        d="M2,3H10A2,2 0 0,1 12,1A2,2 0 0,1 14,3H22V5H21V16H15.25L17,22H15L13.25,16H10.75L9,22H7L8.75,16H3V5H2V3M5,5V14H19V5H5Z"/>
</SvgIcon>);
const FinanceIcon = props => (<SvgIcon {...props}>
    <path
        d="M3,13H7V23H3V13M10,14H14V23H10V14M17,9H21V23H17V9M17,1H21V5H20V3.06L11.97,11.09L8,7.12L3.4,11.72L2.34,10.66L8,5L11.97,8.97L18.94,2H17V1Z"/>
</SvgIcon>);
const PressureIcon = props => (<SvgIcon {...props}>
    <path
        d="M13.014,11.002c-1.107,0.008-2.008-0.884-2.016-1.987c-0.009-1.107,0.879-2.007,1.987-2.016c0.166-0.001,0.324,0.023,0.478,0.06c1.156-0.905,2.667-2.085,2.839-2.208c0.298-0.211,0.64-0.236,0.883,0.007c0.24,0.248,0.215,0.623-0.01,0.886c-0.077,0.091-1.295,1.622-2.229,2.798c0.034,0.143,0.056,0.291,0.057,0.444C15.012,10.088,14.122,10.994,13.014,11.002z M0,21c0-0.553,0.448-1,1-1h9v-2.525C6.51,16.236,4,12.91,4,9c0-4.962,4.038-9,9-9c4.963,0,9,4.038,9,9c0,3.91-2.51,7.236-6,8.475V20h9c0.553,0,1,0.447,1,1s-0.447,1-1,1H1C0.448,22,0,21.553,0,21z M13,15c3.309,0,6-2.691,6-6s-2.691-6-6-6S7,5.691,7,9S9.691,15,13,15z M25,24H1c-0.552,0-1,0.447-1,1s0.448,1,1,1h24c0.553,0,1-0.447,1-1S25.553,24,25,24z"/>
</SvgIcon>);
const ReportIcon = props => (<SvgIcon {...props}>
    <path
        d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M7,20H9V14H7V20M11,20H13V12H11V20M15,20H17V16H15V20Z"/>
</SvgIcon>);

const axisStyle = {
    labels: {
        labelColor: "grey",
        labelWeight: 100,
        labelSize: 11,
        transform: "translate(20, 0)"
    },
    axis: {
        axisColor: "grey",
        axisWidth: 1
    }
};

const styles = theme => ({
    centeredModal: {
        position: 'absolute',
        width: theme.spacing(60),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    flexGrow: {
        flex: '1 1 auto',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: 355,
    },
});

const getEventSeries = (events) => (
    new TimeSeries({
        name: "events",
        events: events.map((event, index, allEvents) => new TimeRangeEvent(
            new TimeRange(moment(event.epoch * 1000).toDate(), allEvents[index + 1] ? moment(allEvents[index + 1].epoch * 1000).toDate() : new Date()),
            {
                title: event.control_status,
                description: event.comment,
                completed: true,
                type: event.control_status,
            }
        ))
    })
)

class BuildingCard extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    }

    state = {
        expanded: false,
        controlStatus: "Unknown",
        controlStatusSetpoint: "Unknown",
        modalOpen: false,
        modalControlStatusSetpoint: "Unknown",
        modalControlContext: "",
        comfortSettingThermal: 5,
        comfortSettingAirquality: 5,
        eventSeries: getEventSeries([]),
    };

    handleExpandClick = () => {
        this.setState({expanded: !this.state.expanded});
    };

    handleControlStatusSetpointChange = (event, checked) => {
        this.setState({
            modalControlStatusSetpoint: checked ? "Active" : "Inactive",
            modalOpen: true
        })
    };
    handleModalControlContextChange = (event) => {
        this.setState({modalControlContext: event.target.value})
    }
    handleModalControlCancel = (event) => {
        this.setState({
            modalControlStatusSetpoint: "Unknown",
            modalOpen: false,
            modalControlContext: ""
        })
    };
    handleModalControlConfirm = (event) => {
        if (this.state.modalControlStatusSetpoint === "Active" || (this.state.modalControlStatusSetpoint !== "Active" && this.state.modalControlContext.length >= 2)) {
            this.props.createLogEntryMutation({
                variables: {
                    projectID: this.props.building.projectID,
                    name: this.props.user ? this.props.user.name + (this.props.user.client ? " (" + this.props.user.client.displayName + ")" : "") : "",
                    description: "dnergy " + (this.state.modalControlStatusSetpoint === "Active" ? "Enabled" : "Disabled") + " - " + this.state.modalControlContext,
                    start: moment(now()),
                    end: moment(now()),
                }
            }).then(response => {
                this.props.updateBuildingControlStatusSetpointMutation({
                    variables: {
                        projectID: this.props.building.projectID,
                        controlStatusSetpoint: this.state.modalControlStatusSetpoint,
                    },
                    refetchQueries: [{
                        query: updateBuildingCache,
                        variables: {
                            projectID: this.props.building.projectID,
                        }
                    }],
                }).then(response => {
                    this.setState({
                        controlStatusSetpoint: this.state.modalControlStatusSetpoint,
                        modalControlStatusSetpoint: "Unknown",
                        modalOpen: false,
                        modalControlContext: ""
                    });
                })
            })
        }
    };

    handleComfortSettingThermalChange = (event, comfortSettingThermal) => {
        this.setState({comfortSettingThermal});
    };
    handleComfortSettingThermalDragEnd = (event) => {
        this.props.updateBuildingComfortSettingThermalMutation({
            variables: {
                projectID: this.props.building.projectID,
                comfortSettingThermal: this.state.comfortSettingThermal,
            },
            refetchQueries: [{
                query: updateBuildingCache,
                variables: {
                    projectID: this.props.building.projectID,
                },
            }],
        })
    };
    handleComfortSettingAirqualityChange = (event, comfortSettingAirquality) => {
        this.setState({comfortSettingAirquality});
    };
    handleComfortSettingAirqualityDragEnd = (event) => {
        this.props.updateBuildingComfortSettingAirqualityMutation({
            variables: {
                projectID: this.props.building.projectID,
                comfortSettingAirquality: this.state.comfortSettingAirquality,
            },
            refetchQueries: [{
                query: updateBuildingCache,
                variables: {
                    projectID: this.props.building.projectID,
                },
            }],
        })
    };


    handleNavigation = (projectID, path) => () => {
        this.props.history.push(path);
    };

    handleEventHover = e => {
        if (e.get("type") === "OK") {
            this.setState({
                hoverEventTitle: e.get("title"),
                hoverEventDescription: " ",
                hoverEventStart: " ",
                hoverEventEnd: " "
            })
        } else {
            this.setState({
                hoverEventTitle: e.get("title"),
                hoverEventDescription: e.get("description"),
                hoverEventStart: "Start: " + moment(e.begin()).format("YYYY-MM-DD HH:mm"),
                hoverEventEnd: "End: " + moment(e.end()).format("YYYY-MM-DD HH:mm")
            })
        }
    }

    handleProps = props => {
        if (props.building) {
            if (props.building.controlStatus !== this.state.controlStatus) {
                this.setState({controlStatus: props.building.controlStatus})
            }
            if (props.building.controlStatusSetpoint !== this.state.controlStatusSetpoint) {
                this.setState({controlStatusSetpoint: props.building.controlStatusSetpoint})
            }
            if (props.building.comfortSettingThermal !== this.state.comfortSettingThermal) {
                this.setState({comfortSettingThermal: props.building.comfortSettingThermal})
            }
            if (props.building.comfortSettingAirquality !== this.state.comfortSettingAirquality) {
                this.setState({comfortSettingAirquality: props.building.comfortSettingAirquality})
            }
            if (props.building.controlEventHistory && props.building.controlEventHistory.payload && props.building.controlEventHistory.payload.length > 0) {
                this.setState({eventSeries: getEventSeries(props.building.controlEventHistory.payload)});
            }
        }
    }

    componentDidMount() {
        this.handleProps(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.handleProps(nextProps)
    }

    render() {
        const {classes, theme} = this.props;

        return (
            <div>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.modalOpen}
                >
                    <div className={classes.centeredModal}>
                        <Typography variant="h5" id="modal-title">
                            {
                                this.state.modalControlStatusSetpoint === "Active"
                                    ? "Start dnergy Control"
                                    : "Stop dnergy Control?"
                            }
                        </Typography>
                        <br/>
                        <Typography variant="body1" id="simple-modal-description">
                            {
                                this.state.modalControlStatusSetpoint === "Active"
                                    ? ""
                                    : "Revert to scheduled or manual control. This event is logged for quality analysis purposes, please describe the reason for shutdown."
                            }
                        </Typography>
                        <br/>
                        <TextField
                            id="simple-modal-context"
                            label={this.state.modalControlStatusSetpoint === "Active"
                                ? "Log message [optional]"
                                : "Cause for disabling dnergy [required]"
                            }
                            error={this.state.modalControlStatusSetpoint !== "Active" && this.state.modalControlContext.length < 2}
                            multiline
                            rows="4"
                            style={{minWidth: "80%"}}
                            defaultValue={this.state.modalControlContext}
                            variant="outlined"
                            onChange={this.handleModalControlContextChange}
                        />
                        <br/>
                        <br/>
                        <Button variant="contained" color="primary"
                                onClick={this.handleModalControlCancel}>Cancel</Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button variant="contained" color="primary"
                                onClick={this.handleModalControlConfirm}>Confirm</Button>
                    </div>
                </Modal>
                <CurrentUserContext.Consumer>{me => {
                    return (
                        <Card className={classes.card}>
                            <CardHeader
                                style={{title: {}}}
                                avatar={<Avatar aria-label="Building"
                                                style={{backgroundColor: computeStatusColor(this.state.controlStatus, this.state.controlStatusSetpoint, me, this.props.building)}}>&nbsp;</Avatar>}
                                action={(me.role !== "READER") ?
                                    <Switch checked={this.state.controlStatusSetpoint === "Active"}
                                            onChange={this.handleControlStatusSetpointChange}/> : null}
                                title={this.props.building.displayName + (this.state.controlStatus === this.state.controlStatusSetpoint ? "" : this.state.controlStatusSetpoint === "Active" ? " [Activating ...]" : " [Deactivating ...]")}
                                //subheader={this.props.building.location && this.props.building.location.city}
                            />
                            <CardMedia
                                className={classes.media}
                                image={this.props.building.image ? this.props.building.image.file.url : "/static/images/cards/generic_building_image.png"}
                                title={this.props.building.displayName}
                            />
                            <CardActions disableSpacing>
                                <IconButton aria-label="Performance" title="Performance"
                                            onClick={this.handleNavigation(this.props.building.projectID, '/buildings/' + this.props.building.projectID + '/info')}>
                                    <PresentationIcon/>
                                </IconButton>
                                <IconButton aria-label="Results" title="Results"
                                            onClick={this.handleNavigation(this.props.building.projectID, '/buildings/' + this.props.building.projectID + "/results")}>
                                    <FinanceIcon/>
                                </IconButton>
                                <IconButton aria-label="Operation" title="Operation"
                                            onClick={this.handleNavigation(this.props.building.projectID, '/buildings/' + this.props.building.projectID + "/operation")}>
                                    <PressureIcon/>
                                </IconButton>
                                <IconButton aria-label="Reports" title="Reports"
                                            onClick={this.handleNavigation(this.props.building.projectID, '/buildings/' + this.props.building.projectID + "/reports")}>
                                    <ReportIcon/>
                                </IconButton>
                                <div className={classes.flexGrow}/>
                                <IconButton
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: this.state.expanded,
                                    })}
                                    onClick={this.handleExpandClick}
                                    aria-expanded={this.state.expanded}
                                    aria-label="Show more"
                                >
                                    <ExpandMoreIcon/>
                                </IconButton>
                            </CardActions>
                            <Collapse in={false} timeout="auto" unmountOnExit>
                                <CardContent c>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                Comfort Settings
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom>
                                                Thermal Comfort
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {me.role === "READER" ?
                                                <Slider min={1} max={9} step={1}
                                                        value={this.state.comfortSettingThermal}
                                                        track={{backgroundColor: theme.palette.secondary.main}}
                                                />
                                                :
                                                <Slider min={1} max={9} step={1}
                                                        value={this.state.comfortSettingThermal}
                                                        onChange={this.handleComfortSettingThermalChange}
                                                        onDragEnd={this.handleComfortSettingThermalDragEnd}
                                                        track={{backgroundColor: theme.palette.secondary.main}}
                                                />
                                            }
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography gutterBottom>
                                                Air Quality
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {me.role === "READER" ?
                                                <Slider
                                                    min={1} max={9} step={1}
                                                    value={this.state.comfortSettingAirquality}
                                                    track={{backgroundColor: theme.palette.secondary.main}}
                                                />
                                                :
                                                <Slider
                                                    min={1} max={9} step={1}
                                                    value={this.state.comfortSettingAirquality}
                                                    onChange={this.handleComfortSettingAirqualityChange}
                                                    onDragEnd={this.handleComfortSettingAirqualityDragEnd}
                                                    track={{backgroundColor: theme.palette.secondary.main}}
                                                />
                                            }
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Collapse>
                            <Divider/>
                            <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <Grid container alignItems="center">
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                7-Day Status History
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Resizable>
                                                <ChartContainer
                                                    timeRange={TimeRange.lastSevenDays()}
                                                    format={d => moment(d).format("ddd")}
                                                    timeAxisTickCount={7}
                                                    timeAxisStyle={axisStyle}
                                                >
                                                    <ChartRow height={30} timeFormat="" trackerTimeFormat="">
                                                        <Charts>
                                                            <EventChart
                                                                series={this.state.eventSeries}
                                                                style={(event, state) => ({fill: event.get("type") === "Active" ? me.client.colorStateActive : me.client.colorStateInactive})}
                                                                onMouseOver={this.handleEventHover}/>
                                                        </Charts>
                                                    </ChartRow>
                                                </ChartContainer>
                                            </Resizable>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6"
                                                        gutterBottom>{this.state.hoverEventTitle}<br/></Typography>
                                            <Typography
                                                gutterBottom>{this.state.hoverEventDescription}<br/></Typography>
                                            <Typography gutterBottom>{this.state.hoverEventStart}<br/></Typography>
                                            <Typography gutterBottom>{this.state.hoverEventEnd}<br/></Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Collapse>
                        </Card>
                    )
                }}
                </CurrentUserContext.Consumer>
            </div>
        );
    }
}


const createLogEntry = gql`
mutation createLogEntryMutation($projectID: String!, $name: String!, $description: String!, $start: DateTime!, $end: DateTime!) {
createLogEntry(
    projectID:$projectID
    name:$name
    description:$description
    start:$start
    end:$end
) { id }}`

const updateBuildingControlStatusSetpoint = gql`
mutation updateBuildingControlStatusSetpoint($projectID: String!, $controlStatusSetpoint: String!) {
  updateBuildingControlStatusSetpoint(
    projectID: $projectID
    controlStatusSetpoint: $controlStatusSetpoint
  ) { id controlStatusSetpoint }
}`;

const updateBuildingComfortSettingThermal = gql`
mutation updateBuildingComfortSettingThermal($projectID: String!, $comfortSettingThermal: Int!) {
  updateBuildingComfortSettingThermal(
    projectID: $projectID
    comfortSettingThermal: $comfortSettingThermal
  ) { id comfortSettingThermal }
}`;

const updateBuildingComfortSettingAirquality = gql`
mutation updateBuildingComfortSettingAirquality($projectID: String!, $comfortSettingAirquality: Int!) {
  updateBuildingComfortSettingAirquality(
    projectID: $projectID
    comfortSettingAirquality: $comfortSettingAirquality
  ) { id comfortSettingAirquality }
}`;

const updateBuildingCache = gql`
query updateBuildingCache($projectID: String!) {
  building(where: {projectID: $projectID}) {
        id
        comfortSettingAirquality
        comfortSettingThermal
        controlStatusSetpoint
    }
}`;
export default compose(
    graphql(createLogEntry, {name: 'createLogEntryMutation'}),
    graphql(updateBuildingControlStatusSetpoint, {name: 'updateBuildingControlStatusSetpointMutation'}),
    graphql(updateBuildingComfortSettingThermal, {name: 'updateBuildingComfortSettingThermalMutation'}),
    graphql(updateBuildingComfortSettingAirquality, {name: 'updateBuildingComfortSettingAirqualityMutation'}),
)(
    withStyles(styles, {withTheme: true})(
        withRouter(
            BuildingCard)));