import React, {createContext, useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import gql from 'graphql-tag';
import {useQuery} from '@apollo/react-hooks';

const SelectedBuildingContext = createContext();

const GET_BASE_BUILDING_INFORMATION = gql`
  query getBaseBuildingInformation($projectID: String!) {
    building(where: {projectID: $projectID}) {
        id
        projectID
        displayName
        lastDataPushDateTime
    }
  }
`;

// Helper function to extract projectID from the URL
const getProjectIDFromPath = (pathname) => {
    const pathArray = pathname.split('/');
    if (pathArray[1] === 'buildings' && pathArray.length >= 3) {
        return pathArray[2];
    }
    return null;
};

export const SelectedBuildingProvider = ({children}) => {
    const location = useLocation();

    // Initialize selectedProjectID with the value from the URL
    const [selectedProjectID, setSelectedProjectID] = useState(() => getProjectIDFromPath(location.pathname));
    const [selectedBuilding, setSelectedBuilding] = useState(null);

    console.log('-- initialized selectedBuilding provider --');
    console.log('selectedProjectID', selectedProjectID);
    const {data, loading, error, refetch} = useQuery(GET_BASE_BUILDING_INFORMATION, {
        variables: {projectID: selectedProjectID},
        skip: !selectedProjectID,
    });

    useEffect(() => {
        const projectIDFromPath = getProjectIDFromPath(location.pathname);
        if (projectIDFromPath && projectIDFromPath !== selectedProjectID) {
            console.log('set projectID to ', projectIDFromPath, ' from path');
            setSelectedProjectID(projectIDFromPath);
        }
    }, [location, selectedProjectID]); // TODO: no dedepency on selectedProjectID

    useEffect(() => {
        if (selectedProjectID) {
            console.log('fetching data ', selectedProjectID);
            refetch({projectID: selectedProjectID});
        }
    }, [selectedProjectID, refetch]);

    useEffect(() => {
        console.log('data', data);
        if (data && data.building) {
            setSelectedBuilding(data.building);
        }
    }, [data]);

    const contextValue = React.useMemo(() => ({
        selectedBuilding,
        selectedProjectID,
        setSelectedProjectID,
        loading,
        error
    }), [selectedBuilding, selectedProjectID, loading, error]);

    return (
        <SelectedBuildingContext.Provider value={contextValue}>
            {children}
        </SelectedBuildingContext.Provider>
    );
};

export const useSelectedBuilding = () => {
    const context = useContext(SelectedBuildingContext);
    if (!context) {
        throw new Error('useSelectedBuilding must be used within a SelectedBuildingProvider');
    }
    return context;
};

export default SelectedBuildingProvider;