import React, {Component} from 'react'

import {graphql} from 'react-apollo'
import gql from 'graphql-tag'

import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress';

import ChartHistoryId from './ChartHistoryId';

class ChartHistoryWaterCircuits extends Component {
    render() {
        if (this.props.data.error) {
            return (<Typography gutterBottom>Error fetching chart data!</Typography>)
        }
        ;
        if (this.props.data.loading || !this.props.data.building) {
            return (<CircularProgress size={50} color="secondary"/>)
        }
        ;
        if (!this.props.data.building.waterCircuits || this.props.data.building.waterCircuits.length === 0) {
            return (<Typography gutterBottom>No data available</Typography>)
        } else {
            return (
                this.props.data.building.waterCircuits.map((waterCircuit, index) => (
                    <div key={waterCircuit.id}>
                        <Paper style={{padding: 25}}>
                            <Typography variant="subtitle1">{waterCircuit.displayName}</Typography>
                            <Typography>&nbsp;</Typography>
                            <Grid container>
                                <Grid item xs={12}>
                                    <ChartHistoryId
                                        legendGrouping="system"
                                        projectID={this.props.projectID}
                                        systemMetricIds={waterCircuit.systemMetrics.map(sm => sm.id)}
                                        systemMetricGroups={this.props.systemMetricGroups}
                                        systemMetricAxes={this.props.systemMetricAxes}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        <Typography>&nbsp;</Typography>
                    </div>
                ))
            )
        }
    }
}

const ChartHistoryWaterCircuitsQuery = gql`
query ChartHistoryWaterCircuitsQuery ($projectID: String!, $systemTemplateNames: [String!]!, $systemMetricTemplateNames: [String!]!) {
  building( where: {projectID: $projectID} ) {
    id
    waterCircuits (where:{
      systemTemplate:{name_in:$systemTemplateNames}
    }) {
      id
      slug
      displayName
      systemTemplate {
        id
        name
        displayName
      }
      systemMetrics (where: {
        systemMetricTemplate: { name_in: $systemMetricTemplateNames },
        systemMetricTags_none: { key: "hide" value: "true" }
        }) {
        id
      }
    }
  }
}`;

export default graphql(ChartHistoryWaterCircuitsQuery, {
    options: (props, state) => ({
        variables: {
            projectID: props.projectID,
            systemTemplateNames: props.systemMetricGroups.map(smg => smg.systemTemplateName),
            systemMetricTemplateNames: props.systemMetricGroups.map(smg => smg.systemMetricTemplateName),
        }
    })
})(ChartHistoryWaterCircuits);
