import React from 'react';
import {useSelectedBuilding} from './contexts/SelectedBuilding';

export const withSelectedProjectID = (WrappedComponent) => {
    return (props) => {
        const {selectedBuilding} = useSelectedBuilding();
        if (!selectedBuilding) {
            return null;
        }
        return <WrappedComponent {...props} projectID={selectedBuilding.projectID}/>;
    };
};