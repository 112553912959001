import React from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import { CurrentUserContext } from '../contexts/CurrentUser';

import { LinkTab } from '../components/Common'

import { Switch, Route, Redirect } from 'react-router-dom'

import Tabs from '@material-ui/core/Tabs';

import DataInputInvoices from '../components/DataInputInvoices'
import DataInputCO2 from '../components/DataInputCO2'
import DataInputCost from '../components/DataInputCost'
import DataInputBuilding from '../components/DataInputBuilding'
import DataInputBuildingFeatureList from '../components/DataInputBuildingFeatureList'

const styles = theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
});

const DataInput = props => {
    const { classes, match, location } = props;
    if (match.url === location.pathname) {
        return <Redirect to={`${match.url}/invoices`} push={false}></Redirect>
    }
    return (
        <CurrentUserContext.Consumer>{ me => { return (
            <div className={classes.root}>
                <Tabs value={location.pathname} onChange={() => null}>
                <LinkTab value={`${match.url}/invoices`} to={`${match.url}/invoices`} label="Invoices" />
                <LinkTab value={`${match.url}/co2_emission`} to={`${match.url}/co2_emission`} label="CO2 Emission Factors" />
                <LinkTab value={`${match.url}/cost`} to={`${match.url}/cost`} label="Energy Prices" />
                <LinkTab value={`${match.url}/characteristics`} to={`${match.url}/characteristics`} label="Building Characteristics" />
                { me.role === "SUPERADMIN" &&
                    <LinkTab value={`${match.url}/features`} to={`${match.url}/features`} label="Feature Activation" />
                }
                </Tabs>
                <Switch>
                    <Route exact path={`${match.path}/invoices`} component={DataInputInvoices}></Route>
                    <Route exact path={`${match.path}/co2_emission`} component={DataInputCO2}></Route>
                    <Route exact path={`${match.path}/cost`} component={DataInputCost}></Route>
                    <Route exact path={`${match.path}/characteristics`} component={DataInputBuilding}></Route>
                    { me.role === "SUPERADMIN" &&
                        <Route exact path={`${match.path}/features`} component={DataInputBuildingFeatureList}></Route>
                    }
                    <Route render={() => {
                        return <Redirect to={`${match.url}/invoices`} push={false}></Redirect>
                    }}></Route>
                </Switch>
            </div>)}}
        </CurrentUserContext.Consumer>
      );
}

DataInput.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DataInput);
